import SearchIcon from '@mui/icons-material/Search'
import { InputAdornment, InputProps } from '@mui/material'
import TextField, { TextFieldProps } from '@mui/material/TextField'
import pickBy from 'lodash/pickBy'
import React, { useEffect, useMemo, useRef, useState } from 'react'

type ISearchInputProps = TextFieldProps & {
  /** Optional time in ms to debounce invocation of the onChange callback. */
  debounce?: number
  /** Callback invoked with latest value of input. Or the current value after a debounce. */
  onValueChange: (val: string) => void
  defaultValue?: string
  InputProps?: InputProps
}

/**
 * Debounce-able search input. Intended to replace usage of react-search-input in CoderPad.io, since that package
 * is very outdated and using deprecated React APIs.
 */
export const SearchInput: React.FC<React.PropsWithChildren<ISearchInputProps>> = (props) => {
  const { debounce, onValueChange, InputProps } = props

  const [searchText, setSearchText] = useState(props.defaultValue || '')
  const prevText = useRef(searchText)

  // Effect to call onChange, possibly debounced.
  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>

    if (debounce) {
      // If a debounce value is specified, set a timeout to call onChange.
      timeout = setTimeout(() => {
        if (searchText !== prevText.current) {
          onValueChange(searchText)
        }
        prevText.current = searchText
      }, debounce)
    } else {
      // Otherwise just call the onChange handler.
      if (searchText !== prevText.current) {
        prevText.current = searchText
        onValueChange(searchText)
      }
    }

    // Clear the timeout when the effect re-runs. This is effectively cancelling the last deferred calling of
    // onChange and letting the latest timeout live.
    return () => {
      clearTimeout(timeout)
    }
  }, [searchText])

  const mergedInputProps = useMemo(() => {
    return Object.assign(
      {
        sx: Object.assign(
          {
            paddingTop: '2px',
            paddingBottom: '2px',
          },
          InputProps?.sx
        ),
        startAdornment: Object.assign(
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>,
          InputProps?.startAdornment
        ),
      },
      InputProps
    )
  }, [])

  // Set up the props for the material-ui TextField.
  // The onChange prop is given a callback to update local searchText state, which in turn invokes the onValueChange
  // handler immediately or after optional debounce time.
  // Memoize the TextField props based on SearchInput props. pickBy can be time consuming.
  const tfProps = useMemo(
    () =>
      pickBy(
        {
          type: 'search',
          ...props,
          onChange: (e: React.ChangeEvent<HTMLInputElement>) => setSearchText(e.target.value),
          InputProps: mergedInputProps,
        },
        (value: any, prop: string) => prop !== 'onValueChange'
      ),
    [props]
  )

  return <TextField {...tfProps} />
}

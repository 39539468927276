import React from 'react'

import { getTool, ITool } from '../Toolbar/toolDefinitions'
import { ToolItem } from '../Toolbar/ToolItem/ToolItem'
import { ControlBar } from './ControlBar'

const undoTool = getTool('undo') as ITool
const redoTool = getTool('redo') as ITool
const clearTool = getTool('clear') as ITool

interface ICorrectionControlsProps {
  setTool: (tool: string) => void
  canRedo: boolean
  canUndo: boolean
  redo: () => void
  undo: () => void
  isClearModeOpen: boolean
  toggleConfirmClearMode: () => void
}

export const CorrectionControls: React.FC<React.PropsWithChildren<ICorrectionControlsProps>> = (
  props
) => {
  const {
    // setTool,
    canRedo,
    canUndo,
    redo,
    undo,
    isClearModeOpen,
    toggleConfirmClearMode,
  } = props

  return (
    <ControlBar
      items={[
        <ToolItem
          key="undo"
          toolName={undoTool.name}
          Icon={undoTool.icon}
          onClick={undo}
          toolTitle={undoTool.title}
          isDisabled={!canUndo}
        />,
        <ToolItem
          key="redo"
          toolName={redoTool.name}
          Icon={redoTool.icon}
          onClick={redo}
          toolTitle={redoTool.title}
          isDisabled={!canRedo}
        />,
        <ToolItem
          key="clear"
          toolName={clearTool.name}
          Icon={clearTool.icon}
          isActive={isClearModeOpen}
          onClick={toggleConfirmClearMode}
          toolTitle={clearTool.title}
        />,
      ]}
    />
  )
}

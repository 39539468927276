import { Slider } from '@mui/material'
import React, { FC, useCallback, useContext, useEffect, useState } from 'react'

import { ToolbarsContext } from '../../Whiteboard'
import { ToolControlItem } from './ToolControlItem'

export const LineWidthControl: FC<React.PropsWithChildren<unknown>> = () => {
  const { setDefaultLineWidth, wbEngine, drawingTheme } = useContext(ToolbarsContext)
  const [width, setWidth] = useState(2)

  useEffect(() => {
    let line = null
    if (wbEngine?.selectedObjects?.length && wbEngine.selectedObjects[0].isLineContainer) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      line = wbEngine?.selectedObjects[0].objects.find(
        (obj: any) => obj.type === 'Line' || obj.type === 'Path'
      )
    } else {
      line = wbEngine?.selectedObjects[0]
    }
    setWidth(line?.strokeWidth ?? 2)
  }, [wbEngine?.selectedObjects])

  const handleWidthSelection = useCallback(
    (width: number) => {
      wbEngine!.setOnSelection({ strokeWidth: width })
      setWidth(width)
      setDefaultLineWidth(width)
    },
    [setDefaultLineWidth, wbEngine]
  )

  if (!wbEngine) return null

  return (
    <>
      <ToolControlItem tooltip="Line Thickness">
        <Slider
          min={1}
          max={20}
          value={width}
          step={1}
          size="small"
          onChange={(e, value) => handleWidthSelection(value as number)}
          sx={{
            width: '100px',
            '& .MuiSlider-thumb': {
              color: drawingTheme.toolbarColor,
            },
            '& .MuiSlider-track': {
              color: 'transparent',
            },
            '& .MuiSlider-rail': {
              color: drawingTheme.toolbarColor,
            },
          }}
        />
      </ToolControlItem>
    </>
  )
}

import {
  Account,
  Billing,
  Integration,
  Upgrade,
  Users,
} from '@codinpad/shared-components/components/icons/index.js'
import Link from '@mui/material/Link'
import { styled } from '@mui/material/styles'
import React, { useCallback, useMemo } from 'react'
import { NavLink } from 'react-router-dom'
import { useAppManagingUsers } from 'utils/hooks/useAppManagingUsers'
import { Permissions } from 'utils/hooks/useUserPermissions'

import { ReactComponent as Insights } from '../../../../../../assets/images/insights.svg'
import { makeUseOrganizationHook } from '../../../../../graphql/hooks/organization/useOrganization/useOrganization'
import { useUserInfoHookBuilder } from '../../../../../graphql/hooks/users/useCurrentUser/useUserInfo'
import { Application, Plan, Quota, User } from '../../../../../graphql/types'
import {
  cgBillingPageRoute,
  cgUsersPageRoute,
  userBillingPageRoute,
} from '../../../../../utils/routeHelpers'
import { track } from '../../../../main/coderpad_analytics'

const useOrganization = makeUseOrganizationHook(`
  name
`)

// Note: This is only required to override default Bootstrap styling
const BasicLink = styled(Link)({
  '&:focus': {
    outline: 0,
    textDecoration: 'none',
  },
})

const RouterLink = styled(NavLink)({
  '&:focus': {
    outline: 0,
    textDecoration: 'none',
  },
})

type UserInfo = Pick<User, 'email' | 'organizationOwner' | 'permissions'> & {
  activeQuota: Pick<Quota, 'plan'> & { planObj: Pick<Plan, 'codingameXsellPlan' | 'title'> }
}

export function useSettingsItems(noRouter = false) {
  const { organization } = useOrganization()
  const { userInfo } = useUserInfoHookBuilder<UserInfo>(`
    email
    organizationOwner
    permissions
    activeQuota {
      id
      plan
      planObj {
        id
        codingameXsellPlan
        title
      }
    }
  `)()
  const hasOrganization = organization != null
  const hasAdminPrivs =
    userInfo != null && (userInfo?.organizationOwner === true || !hasOrganization)
  const isCodingameXSell = userInfo?.activeQuota?.planObj?.codingameXsellPlan ?? false
  const appManagingUsers = useAppManagingUsers()
  const redirectToCG = appManagingUsers === Application.Screen
  const hasInterviewPermission =
    userInfo?.permissions && userInfo?.permissions.includes(Permissions.ACCESS_INTERVIEW)
  const canAccessUsers = hasOrganization && userInfo?.permissions.includes(Permissions.ACCESS_USERS)

  const linkFormatter = useCallback(
    (path: string) => {
      if (noRouter) {
        return {
          component: BasicLink,
          href: path,
        }
      } else {
        return {
          component: RouterLink,
          to: path,
        }
      }
    },
    [noRouter]
  )

  const items = useMemo(() => {
    return [
      {
        key: 'team-settings',
        icon: <Account />,
        label: 'Organization settings',
        ...linkFormatter('/dashboard/organization/settings'),
        onClick: () =>
          track('topnav settings menu item clicked', {
            item: 'account settings',
            version: 'xsell',
          }),
        enabled: hasAdminPrivs && hasOrganization,
      },
      {
        key: 'users',
        icon: <Users />,
        label: 'Users',
        component: BasicLink,
        href: redirectToCG ? cgUsersPageRoute : '/dashboard/users',
        onClick: () =>
          track('topnav settings menu item clicked', {
            item: 'users',
            version: 'xsell',
          }),
        enabled: canAccessUsers,
      },
      {
        key: 'create-a-team',
        icon: <Users />,
        label: 'Create a Team',
        component: BasicLink,
        href: '/organization/new',
        onClick: () =>
          track('topnav settings menu item clicked', {
            item: 'create a team',
            version: 'xsell',
          }),
        enabled: !hasOrganization && hasInterviewPermission,
      },
      {
        key: 'insights',
        icon: <Insights />,
        label: 'Insights',
        component: BasicLink,
        href: '/dashboard/insights',
        onClick: () =>
          track('topnav settings menu item clicked', {
            item: 'insights',
            version: 'xsell',
          }),
        enabled: true,
      },
      {
        key: 'billing',
        icon: <Billing />,
        label: 'Billing',
        component: BasicLink,
        href: isCodingameXSell ? cgBillingPageRoute : '/dashboard/billing',
        onClick: () =>
          track('topnav settings menu item clicked', {
            item: 'billing',
            version: 'xsell',
          }),
        enabled: hasAdminPrivs,
      },
      {
        key: 'integrations',
        icon: <Integration />,
        label: 'Integrations',
        component: BasicLink,
        href: '/dashboard/integrations',
        onClick: () =>
          track('topnav settings menu item clicked', {
            item: 'integrations',
            version: 'xsell',
          }),
        enabled: hasAdminPrivs && hasInterviewPermission,
      },
      {
        key: 'upgrade',
        icon: <Upgrade />,
        label: 'Upgrade',
        highlighted: true,
        component: BasicLink,
        href: userBillingPageRoute(userInfo as User),
        enabled: hasInterviewPermission,
      },
    ]
      .filter(({ enabled }) => enabled ?? true)
      .map(({ enabled, ...item }) => item)
  }, [
    linkFormatter,
    hasAdminPrivs,
    hasOrganization,
    redirectToCG,
    hasInterviewPermission,
    isCodingameXSell,
    userInfo,
  ])

  return items
}

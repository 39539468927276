import * as Icons from './icons'

export interface ITool {
  name: string
  title: string
  icon: React.ComponentType<React.PropsWithChildren<unknown>>
  keymap?: string
  keycode?: number
  group?: string
}

/**
 * Export an array of tool definitions.
 */
export const TOOLS: ITool[] = [
  { name: 'select', title: 'Select', keymap: 'V', keycode: 86, icon: Icons.Select },
  { name: 'text', title: 'Text', keymap: 'T', keycode: 84, icon: Icons.Text },

  {
    name: 'pencil',
    title: 'Pencil',
    keymap: 'P',
    keycode: 80,
    icon: Icons.Pencil,
    group: 'strokeTools',
  },
  {
    name: 'marker',
    title: 'Marker',
    keymap: 'M',
    keycode: 77,
    icon: Icons.Marker,
    group: 'strokeTools',
  },
  {
    name: 'lineStraight',
    title: 'Line',
    keymap: 'L',
    keycode: 76,
    icon: Icons.Line,
    group: 'strokeTools',
  },
  {
    name: 'lineArrow',
    title: 'Line Arrow',
    keymap: 'Q',
    keycode: 81,
    icon: Icons.ArrowLine,
    group: 'strokeTools',
  },
  {
    name: 'lineDoubleArrow',
    title: 'Line Double Arrow',
    keymap: 'H',
    keycode: 72,
    icon: Icons.ArrowDouble,
    group: 'strokeTools',
  },
  {
    name: 'arrow',
    title: 'Arrow',
    keymap: 'A',
    keycode: 65,
    icon: Icons.FreehandArrow,
    group: 'strokeTools',
  },

  {
    name: 'eraser',
    title: 'Eraser',
    keymap: 'E',
    keycode: 69,
    icon: Icons.Erase,
    group: 'eraseTools',
  },
  {
    name: 'objectDeleter',
    title: 'Delete Objects',
    icon: Icons.ObjectDelete,
    group: 'eraseTools',
  },
  { name: 'clear', title: 'Clear', keymap: 'X', keycode: 88, icon: Icons.Trash },

  {
    name: 'rectangle',
    title: 'Rectangle',
    keymap: 'R',
    keycode: 82,
    icon: Icons.Rectangle,
    group: 'shapeTools',
  },
  {
    name: 'ellipse',
    title: 'Ellipse',
    keymap: 'B',
    keycode: 66,
    icon: Icons.Ellipse,
    group: 'shapeTools',
  },
  {
    name: 'diamond',
    title: 'Diamond',
    keymap: 'D',
    keycode: 68,
    icon: Icons.Diamond,
    group: 'shapeTools',
  },
  {
    name: 'cylinder',
    title: 'Cylinder',
    keymap: 'J',
    keycode: 74,
    icon: Icons.Cylinder,
    group: 'shapeTools',
  },
  {
    name: 'palette',
    title: 'Color Picker',
    keymap: 'C',
    keycode: 67,
    icon: Icons.Palette,
  },

  { name: 'undo', title: 'Undo', keymap: 'Cmd-Z', icon: Icons.Undo },
  { name: 'redo', title: 'Redo', keymap: 'Cmd-Shift-Z', icon: Icons.Redo },

  { name: 'upload', title: 'Upload Image', icon: Icons.Upload },

  { name: 'zoomIn', title: 'Zoom In', keymap: '+', keycode: 187, icon: Icons.ZoomIn },
  { name: 'zoomOut', title: 'Zoom Out', keymap: '-', keycode: 189, icon: Icons.ZoomOut },

  { name: 'pan', title: 'Pan', keymap: 'O', keycode: 79, icon: Icons.Pan },
  { name: 'fitContent', title: 'Fit to Pane', keymap: 'F', keycode: 70, icon: Icons.FitToPane },

  {
    name: 'toggleGrid',
    title: 'Toggle grid',
    keymap: 'G',
    keycode: 71,
    icon: Icons.Grid,
  },
]

/**
 * Export a convenience function to get a tool definition by name.
 */
export const getTool = (name: string) => {
  return TOOLS.find((tool) => tool.name === name)
}

import { Box, useTheme } from '@mui/material'
import React, { useContext, useState } from 'react'

import { getTool, ITool } from '../Toolbar/toolDefinitions'
import { ToolItem } from '../Toolbar/ToolItem/ToolItem'
import { ToolbarsContext } from '../Whiteboard'
import { ControlBar } from './ControlBar'

enum ZoomClick {
  NONE,
  ZOOM_OUT,
  ZOOM_RESET,
  ZOOM_IN,
}

const zoomInTool = getTool('zoomIn') as ITool
const zoomOutTool = getTool('zoomOut') as ITool
const panTool = getTool('pan') as ITool
const fitContentTool = getTool('fitContent') as ITool
const toggleGridTool = getTool('toggleGrid') as ITool

interface IViewportControlsProps {
  setTool: (tool: string) => void
  activeTool: string
  defaultZoomLevel: number
  zoomLevel: number
  zoomIn: () => void
  zoomOut: () => void
  zoomReset: () => void
  fitContentToScreen: () => void
  toggleGrid: () => void
  isGridOn: boolean
}

export const ViewportControls: React.FC<React.PropsWithChildren<IViewportControlsProps>> = (
  props
) => {
  const theme = useTheme()
  const { drawingTheme } = useContext(ToolbarsContext)
  const {
    setTool,
    activeTool,
    defaultZoomLevel,
    zoomLevel,
    zoomIn,
    zoomOut,
    zoomReset,
    fitContentToScreen,
    toggleGrid,
    isGridOn,
  } = props

  const [zooming, setZooming] = useState(ZoomClick.NONE)

  return (
    <ControlBar
      items={[
        <Box
          key="zoomOut"
          onMouseDown={() => setZooming(ZoomClick.ZOOM_OUT)}
          onMouseUp={() => setZooming(ZoomClick.NONE)}
        >
          <ToolItem
            toolName={zoomOutTool.name}
            Icon={zoomOutTool.icon}
            onClick={zoomOut}
            toolTitle={zoomOutTool.title}
            isActive={zooming === ZoomClick.ZOOM_OUT}
          />
        </Box>,
        <Box
          key="zoomPercentage"
          onMouseDown={() => setZooming(ZoomClick.ZOOM_RESET)}
          onMouseUp={() => setZooming(ZoomClick.NONE)}
          onClick={zoomReset}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: theme.spacing(5),
            height: '100%',
            cursor: 'pointer',
            borderRadius: theme.spacing(0.5),
            color: drawingTheme.toolbarColor,
            backgroundColor:
              zooming === ZoomClick.ZOOM_RESET ? theme.palette.primary.main : 'initial',
          }}
        >
          {Math.floor((zoomLevel / defaultZoomLevel) * 100.0)}%
        </Box>,
        <Box
          key="zoomIn"
          onMouseDown={() => setZooming(ZoomClick.ZOOM_IN)}
          onMouseUp={() => setZooming(ZoomClick.NONE)}
        >
          <ToolItem
            toolName={zoomInTool.name}
            Icon={zoomInTool.icon}
            onClick={zoomIn}
            toolTitle={zoomInTool.title}
            isActive={zooming === ZoomClick.ZOOM_IN}
          />
        </Box>,
        <ToolItem
          key="pan"
          toolName={panTool.name}
          Icon={panTool.icon}
          onClick={setTool}
          isActive={activeTool === panTool.name}
          toolTitle={panTool.title}
        />,
        <ToolItem
          key="fit"
          toolName={fitContentTool.name}
          Icon={fitContentTool.icon}
          onClick={fitContentToScreen}
          toolTitle={fitContentTool.title}
        />,
        <ToolItem
          key="grid"
          toolName={toggleGridTool.name}
          Icon={toggleGridTool.icon}
          color={isGridOn ? '' : drawingTheme.toolbarColorDisabled}
          onClick={toggleGrid}
          toolTitle={toggleGridTool.title}
        />,
      ]}
    />
  )
}

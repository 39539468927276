import { Box } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'classnames'
import React, { useCallback, useState } from 'react'

import { ReactComponent as UploadIcon } from './UploadIcon.svg'

interface IFileDropAreaProps {
  onDrop: (files: any[]) => void
}

export const useStyles = makeStyles((theme) => ({
  dropArea: {
    borderRadius: theme.shape.borderRadius,
  },
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1,
    backgroundColor: 'rgba(255, 255, 255, 0.9)',
    // This prevents children of the informational overlay from triggering drag end/leave events and thrashing the
    // `isDragging` state of the drop area component.
    '&> *': {
      pointerEvents: 'none',
    },
  },
}))
/**
 * Component for a drag-and-drop enabled file input.
 */
export const FileDropArea: React.FC<React.PropsWithChildren<IFileDropAreaProps>> = ({
  onDrop,
  children,
}) => {
  const styles = useStyles()

  const [isDragging, setIsDragging] = useState(false)

  const onFileDrop = useCallback(
    (e: any) => {
      e.preventDefault()
      onDrop(e.dataTransfer.files)
      setIsDragging(false)
    },
    [onDrop]
  )
  const onDragOver = useCallback((e: any) => {
    e.preventDefault()
    setIsDragging(true)
  }, [])
  const endDrag = () => {
    setIsDragging(false)
  }

  return (
    <Box
      className={clsx(styles.dropArea, {
        active: !!isDragging,
      })}
      position="relative"
      onDrop={onFileDrop}
      onDragOver={onDragOver}
      data-testid="filedroparea"
    >
      {children}
      {isDragging && (
        <div
          className={styles.overlay}
          data-testid="filedroparea-overlay"
          onDragLeave={endDrag}
          onDragEnd={endDrag}
        >
          <UploadIcon />
          <div>Drop file to upload</div>
        </div>
      )}
    </Box>
  )
}

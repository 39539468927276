import React from 'react'
import { themeIcons } from 'seti-icons'

import { ReactComponent as CpadIcon } from '../../../../../assets/images/language_icons/cpad.svg'
import { ReactComponent as SvelteIcon } from '../../../../../assets/images/language_icons/svelte.svg'

interface IFileIconProps {
  fileName: string
  isDarkTheme: boolean
}

const _FileIcon: React.FC<React.PropsWithChildren<IFileIconProps>> = ({
  fileName,
  isDarkTheme,
}) => {
  // seti-icons does not have a Svelte icon, so until we fork that lib and add
  // one to it, fall back to our own icon for `*.svelte` files
  if (/.*\.svelte/.test(fileName)) {
    return (
      <div className="file-icon-wrapper" style={{ textAlign: 'center' }}>
        <SvelteIcon style={{ width: '50%' }} />
      </div>
    )
  } else if (fileName === '.cpad') {
    return (
      <div className="file-icon-wrapper" style={{ textAlign: 'center' }}>
        <CpadIcon style={{ width: '50%' }} />
      </div>
    )
  }

  // these colors were taken as-is from the seti-icons readme, with the exception
  // of `white`, which was too low-contrast in our Light Theme
  // https://www.npmjs.com/package/seti-icons
  const getIcon = themeIcons({
    blue: '#268bd2',
    grey: '#657b83',
    'grey-light': '#839496',
    green: '#859900',
    orange: '#cb4b16',
    pink: '#d33682',
    purple: '#6c71c4',
    red: '#dc322f',
    white: isDarkTheme ? '#fdf6e3' : '#839496',
    yellow: '#b58900',
    ignore: '#586e75',
  })

  const { svg, color } = getIcon(fileName)

  return (
    <div
      className="file-icon-wrapper"
      style={{ fill: color }}
      dangerouslySetInnerHTML={{ __html: svg }}
    />
  )
}

export const FileIcon = React.memo(_FileIcon)

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material'
import React from 'react'

import { WarningButton } from '../../WarningButton/WarningButton'

interface IClearBoardConfirmationProps {
  container?: HTMLDivElement
  isOpen: boolean
  onConfirmClear: () => void
  onCancel: () => void
}

export const ClearBoardConfirmation: React.FC<
  React.PropsWithChildren<IClearBoardConfirmationProps>
> = ({ container, isOpen, onConfirmClear, onCancel }) => {
  return (
    <Dialog open={isOpen} onClose={onCancel} container={container} fullWidth={true}>
      <DialogTitle>Clear Drawing?</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to clear the drawing? This action cannot be undone!
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onCancel}>
          Cancel
        </Button>
        <WarningButton variant="contained" onClick={onConfirmClear} disableElevation={true}>
          Clear Drawing
        </WarningButton>
      </DialogActions>
    </Dialog>
  )
}

import { Logout, User as UserIcon } from '@codinpad/shared-components/components/icons/index.js'
import { styled } from '@mui/material'
import Link from '@mui/material/Link'
import React, { useCallback, useMemo } from 'react'
import { NavLink } from 'react-router-dom'
import { Permissions, useUserPermissions } from '../../../../../utils/hooks/useUserPermissions'

import { useFetch } from '../../../../../utils/fetch/useFetch'
import { track } from '../../../../main/coderpad_analytics'

// Note: This is only required to override default Bootstrap styling
const BasicLink = styled(Link)({
  '&:focus': {
    outline: 0,
    textDecoration: 'none',
  },
})

const RouterLink = styled(NavLink)({
  '&:focus': {
    outline: 0,
    textDecoration: 'none',
  },
})

function submitForm(path: string, formData: FormData, method = 'post') {
  const form = document.createElement('form')
  form.method = method
  form.action = path
  formData.forEach((value, key) => {
    const hiddenField = document.createElement('input')
    hiddenField.type = 'hidden'
    hiddenField.name = key
    hiddenField.value = value as string
    form.appendChild(hiddenField)
  })
  document.body.appendChild(form)
  form.submit()
}

export function useUserItems(noRouter = false) {
  const { userHasPermission } = useUserPermissions()
  const hasInterviewPermission = userHasPermission(Permissions.ACCESS_INTERVIEW)

  const linkFormatter = useCallback(
    (path: string) => {
      if (noRouter) {
        return {
          component: BasicLink,
          href: path,
        }
      } else {
        return {
          component: RouterLink,
          to: path,
        }
      }
    },
    [noRouter]
  )

  const fetcher = useFetch()
  const logout = useCallback(async () => {
    track('topnav user menu item clicked', {
      item: 'logout',
      version: 'xsell',
    })
    const data = new FormData()
    data.append('authenticity_token', fetcher.authToken)
    data.append('_method', 'delete')
    window.localStorage.clear()
    submitForm('/logout', data)
  }, [fetcher.authToken])

  const items = useMemo(() => {
    return [
      {
        key: 'my-settings',
        icon: <UserIcon />,
        label: 'My Settings',
        ...linkFormatter('/dashboard/settings'),
        onClick: () =>
          track('topnav user menu item clicked', {
            item: 'my settings',
            version: 'xsell',
          }),
        enabled: hasInterviewPermission,
      },
      {
        key: 'logout',
        icon: <Logout />,
        label: 'Logout',
        onClick: logout,
        enabled: true,
      },
    ]
      .filter(({ enabled }) => enabled ?? true)
      .map(({ enabled, ...item }) => item)
  }, [linkFormatter, logout, hasInterviewPermission])

  return items
}

import {
  Box,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  styled,
  TextField,
} from '@mui/material'
import { Alert } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'

import * as queryStates from '../../../../../graphql/queryStates'
import { FileDropArea } from '../../FileDropArea/FileDropArea'
import { useImageUpload } from './api'

// Rails stylesheet is pretty opinionated that file inputs should be display: block. Make sure that doesn't happen.
const FileInput = styled('input')({ display: 'none !important' })
// Remove the bottom margin added by the Rails stylesheet.
const FileInputLabel = styled('label')({ marginBottom: 0 })

interface IImageFormProps {
  container?: HTMLDivElement
  isOpen: boolean
  onLinkSubmit: (imgLink: string) => void
  onRequestClose: () => void
  slug: string
}

export const ImageForm: React.FC<React.PropsWithChildren<IImageFormProps>> = ({
  container,
  isOpen,
  onLinkSubmit,
  onRequestClose,
  slug,
}) => {
  const { uploadImage, fileURL, status, reset } = useImageUpload(slug)
  const [imgUrl, setImgUrl] = useState('')

  const onSubmit = useCallback(
    (e: any) => {
      e.preventDefault()
      if (imgUrl) {
        onLinkSubmit(imgUrl)
        onRequestClose()
      }
    },
    [imgUrl]
  )

  // Reset the query state of the file upload if user interacts with the link field.
  useEffect(() => {
    reset()
  }, [imgUrl])

  useEffect(() => {
    if (fileURL) {
      onLinkSubmit(fileURL)
      onRequestClose()
      reset()
    }
  }, [fileURL])

  const msgSeverity = queryStates.isErrorState(status) ? 'error' : 'success'
  const statusMsg =
    queryStates.isSuccessState(status) || queryStates.isErrorState(status) ? status[1] : ''

  return (
    <Dialog open={isOpen} container={container} fullWidth={true}>
      <FileDropArea onDrop={(files) => uploadImage(files[0])}>
        <DialogTitle>Insert Image</DialogTitle>
        <DialogContent>
          <Box component="form" role="form" onSubmit={onSubmit}>
            <Collapse in={!!statusMsg}>
              {statusMsg && (
                <Box mb={2}>
                  <Alert severity={msgSeverity}>{statusMsg}</Alert>
                </Box>
              )}
            </Collapse>
            <Box mb={2}>
              Drag & drop an image from your desktop, upload an image, or provide a URL to an image.
              4MB max. Supports JPG, PNG and HEIC images.
            </Box>
            <Box mb={2}>
              <FileInputLabel htmlFor="upload-file-field">
                <FileInput
                  id="upload-file-field"
                  data-testid="imageform-hiddenfileinput"
                  type="file"
                  onChange={(e) => {
                    e.target.files?.length && uploadImage(e.target.files[0])
                  }}
                />
                <Button variant="contained" component="span" color="primary">
                  Upload from computer
                </Button>
              </FileInputLabel>
            </Box>
            <TextField
              variant="outlined"
              onChange={(e) => setImgUrl(e.target.value)}
              id="drawingImgUrl"
              label="Image URL"
              helperText="Publicly accessible URL to an image"
              fullWidth={true}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onRequestClose} variant="contained" disableElevation={true}>
            Cancel
          </Button>
          <Button
            color="primary"
            variant="contained"
            disableElevation={true}
            disabled={!imgUrl}
            onClick={onSubmit}
          >
            Add Image
          </Button>
        </DialogActions>
        {queryStates.isLoadingState(status) && (
          <Box
            position="absolute"
            top={0}
            left={0}
            height="100%"
            width="100%"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            bgcolor="#fff"
            zIndex={1}
          >
            <div>Uploading image...</div>
            <Box width="75%" mt={2}>
              <LinearProgress />
            </Box>
          </Box>
        )}
      </FileDropArea>
    </Dialog>
  )
}

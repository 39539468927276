import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import { Box, List, ListItemButton, ListItemText } from '@mui/material'
import { ScrollView } from 'packs/main/ScrollView/ScrollView'
import React, { FC, useCallback, useContext, useEffect, useState } from 'react'

import { ToolbarsContext } from '../../Whiteboard'
import { ToolControlItem } from './ToolControlItem'

const FONT_SIZES = [10, 12, 14, 18, 24, 36, 40, 48, 64, 80, 144, 288]

export const FontSizeControl: FC<React.PropsWithChildren<unknown>> = () => {
  const { wbEngine, drawingTheme, setDefaultFontSize } = useContext(ToolbarsContext)
  const [fontSize, setFontSize] = useState(40)

  useEffect(() => {
    setFontSize(wbEngine?.selectedObjects[0]?.fontSize ?? 40)
  }, [wbEngine?.selectedObjects])

  const updateFontSize = useCallback(
    (size: any) => {
      wbEngine?.setOnSelection({ fontSize: size })
      setFontSize(size)
      setDefaultFontSize(size)
    },
    [wbEngine, setDefaultFontSize]
  )

  const incrementFont = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault()
      e.stopPropagation()
      const startIndex = FONT_SIZES.indexOf(fontSize) ?? 0
      for (let i = startIndex; i < FONT_SIZES.length; i++) {
        if (FONT_SIZES[i] > fontSize) {
          updateFontSize(FONT_SIZES[i])
          return
        }
      }
    },
    [fontSize, updateFontSize]
  )

  const decrementFont = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault()
      e.stopPropagation()
      const startIndex = FONT_SIZES.indexOf(fontSize) ?? FONT_SIZES.length - 1
      for (let i = startIndex; i >= 0; i--) {
        if (FONT_SIZES[i] < fontSize) {
          updateFontSize(FONT_SIZES[i])
          return
        }
      }
    },
    [fontSize, updateFontSize]
  )

  if (!wbEngine) return null

  return (
    <>
      <ToolControlItem
        tooltip="Font"
        popperMenu={({ open, setOpen }) => (
          <ScrollView sx={{ maxHeight: '200px', overflowY: 'scroll' }}>
            <List sx={{ color: drawingTheme.toolbarColor }} dense>
              {FONT_SIZES.map((size) => (
                <ListItemButton
                  key={size}
                  onClick={() => {
                    updateFontSize(size)
                    setOpen(false)
                  }}
                >
                  <ListItemText>{size}</ListItemText>
                </ListItemButton>
              ))}
            </List>
          </ScrollView>
        )}
      >
        <>
          <Box>{fontSize}</Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-evenly',
              marginLeft: 1,
            }}
          >
            <ArrowDropUpIcon
              viewBox="6 6 12 12"
              sx={{ width: '12px', height: '12px' }}
              onClick={incrementFont}
            />
            <ArrowDropDownIcon
              viewBox="6 6 12 12"
              sx={{ width: '12px', height: '12px' }}
              onClick={decrementFont}
            />
          </Box>
        </>
      </ToolControlItem>
    </>
  )
}

import {
  Database,
  Folder,
  Interview,
  Question,
  Sandbox,
} from '@codinpad/shared-components/components/icons/index.js'
import { SidebarListItemProps } from '@codinpad/shared-components/components/sidebar/sidebarListItem/index.js'
import { styled } from '@mui/material'
import Link from '@mui/material/Link'
import React, { useCallback, useMemo } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { Permissions, useUserPermissions } from 'utils/hooks/useUserPermissions'

import { makeUseOrganizationHook } from '../../../../../graphql/hooks/organization/useOrganization/useOrganization'
import { cgBaseUrl } from '../../../../../utils/codingameService'
import { track } from '../../../../main/coderpad_analytics'

const useOrganization = makeUseOrganizationHook(`
  questionsEnabled
`)

// Note: This is only required to override default Bootstrap styling
const BasicLink = styled(Link)({
  '&:focus': {
    outline: 0,
    textDecoration: 'none',
  },
})

const RouterLink = styled(NavLink)({
  '&:focus': {
    outline: 0,
    textDecoration: 'none',
  },
})

export function useSidebarItems(noRouter = false) {
  const location = useLocation()
  const { organization } = useOrganization()
  const { userHasPermission } = useUserPermissions()

  const trackMenuItemClick = (item: string) => {
    track('sidenav menu item clicked', {
      item,
      version: 'xsell',
    })
  }

  const linkFormatter = useCallback(
    (path: string, isCgLink: boolean = false) => {
      if (isCgLink) {
        return {
          href: `${cgBaseUrl}${path}`,
        }
      }

      if (noRouter) {
        return {
          component: BasicLink,
          href: path,
          selected: location.pathname.startsWith(path),
        }
      } else {
        return {
          component: RouterLink,
          to: path,
          selected: location.pathname.startsWith(path),
        }
      }
    },
    [noRouter, location.pathname]
  )

  const items = useMemo<SidebarListItemProps<NavLink | typeof Link>[]>(() => {
    if (!userHasPermission(Permissions.ACCESS_INTERVIEW)) {
      return []
    }

    const its = [
      {
        label: 'Pads List',
        icon: <Interview />,
        ...linkFormatter('/dashboard/pads'),
        onClick: () => trackMenuItemClick('pads'),
      },
    ]
    if (!organization || organization.questionsEnabled) {
      its.push({
        label: 'Questions',
        icon: <Question />,
        ...linkFormatter('/dashboard/questions'),
        onClick: () => trackMenuItemClick('questions'),
      })
    }
    return its.concat([
      {
        label: 'Databases',
        icon: <Database />,
        ...linkFormatter('/dashboard/databases'),
        onClick: () => trackMenuItemClick('databases'),
      },
      {
        label: 'Files',
        icon: <Folder />,
        ...linkFormatter('/dashboard/files'),
        onClick: () => trackMenuItemClick('files'),
      },
      {
        label: 'Sandbox',
        icon: <Sandbox />,
        component: BasicLink,
        href: '/sandbox',
        // @ts-ignore
        target: '_blank',
        onClick: () => trackMenuItemClick('sandbox'),
      },
    ])
  }, [linkFormatter, organization, userHasPermission])

  return items
}

import { Box, useTheme } from '@mui/material'
import React, { ReactElement, useContext } from 'react'

import { ToolbarsContext } from '../Whiteboard'

interface IControlBarProps {
  items: ReactElement[]
  isVertical?: boolean
}

export const ControlBar: React.FC<React.PropsWithChildren<IControlBarProps>> = ({
  items,
  isVertical,
}) => {
  const theme = useTheme()
  const { drawingTheme } = useContext(ToolbarsContext)

  return (
    <Box
      sx={{
        backgroundColor: drawingTheme.toolbarBg,
        display: 'flex',
        flexDirection: isVertical ? 'column' : 'row',
        borderRadius: theme.spacing(0.5),
        maxHeight: isVertical ? null : theme.spacing(5),
      }}
    >
      {items.map((item: ReactElement) => (
        <Box key={item.key}>{item}</Box>
      ))}
    </Box>
  )
}

import { Box, Grid } from '@mui/material'
import React, { FC, ReactNode, useContext } from 'react'

import { ToolbarsContext } from '../Whiteboard'

interface ControlIcon {
  Icon: ReactNode
  onClick: () => void
  active: boolean
}

interface ControlIconGridProps {
  numCols: number
  icons: ControlIcon[]
}

export const ControlIconGrid: FC<React.PropsWithChildren<ControlIconGridProps>> = ({
  numCols,
  icons,
}) => {
  const { drawingTheme } = useContext(ToolbarsContext)

  return (
    <Box
      sx={{
        color: drawingTheme.toolbarColor,
        backgroundColor: drawingTheme.toolbarBg,
        borderRadius: 1,
      }}
    >
      <Grid
        container
        spacing={0}
        sx={{
          width: 36 * numCols + 'px',
        }}
      >
        {icons.map((icon, index) => (
          <Grid key={index} item xs={12 / numCols} onClick={icon.onClick}>
            <Box
              sx={{
                padding: 1,
                display: 'flex',
                justifyContent: 'center',
                cursor: 'pointer',
                '&:hover': {
                  backgroundColor: drawingTheme.modeHovered,
                },
                backgroundColor: icon.active ? drawingTheme.modeHovered : 'none',
              }}
            >
              {icon.Icon}
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  )
}

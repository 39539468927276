import { Product as ProductType } from '@codinpad/shared-components/common/Product'
import Sidebar from '@codinpad/shared-components/components/sidebar/index.js'
import ScopedCssBaseline from '@mui/material/ScopedCssBaseline'
import { useTrialStepUserProgress } from 'graphql/hooks/trialStepUserProgress/useTrialStepUserProgress'
import cookies from 'js-cookie'
import React, { FC, useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { checkEnableTrialHomepage } from 'utils/checkFeatureFlag'
import { cgBaseUrl } from 'utils/codingameService'
import { useProductSwitcherItems } from 'utils/productswitcher/ProductSwitcher'

import { useCurrentPlan } from '../../../../graphql/hooks'
import {
  Organization,
  OrganizationMainChallenge,
  PlanCategorization,
  User,
} from '../../../../graphql/types'
import { userBillingPageRoute } from '../../../../utils/routeHelpers'
import { track } from '../../../main/coderpad_analytics'
import { useAppStateProperty } from '../AppState/AppStateProvider'
import { stepsAvailableLength } from '../TrialHomepage/consts/StepsByMainChallenge'
import { useSidebarItems } from './hooks/useSidebarItems'

interface SharedSidebarProps {
  organization?: Organization
  currentUser?: User
  noRouter?: boolean
  verticalOffset?: number | string
}

const SharedSidebar: FC<React.PropsWithChildren<SharedSidebarProps>> = ({
  organization,
  currentUser,
  noRouter = false,
  verticalOffset = 0,
}) => {
  const { value: expanded, set: setExpanded } = useAppStateProperty('appNavOpen')
  const history = useHistory()
  const upgradeRoute = userBillingPageRoute(currentUser)
  const [isSquareLogo, setIsSquareLogo] = useState(false)
  const { trialChallengeUserProgress } = useTrialStepUserProgress()
  const enableTrialHomepage = checkEnableTrialHomepage()
  const { currentPlan } = useCurrentPlan()

  useEffect(() => {
    cookies.set('app_nav_state', expanded ? '' : '1')
    if (expanded) {
      document.getElementById('new-content-container')?.classList.remove('navClosed')
    } else {
      document.getElementById('new-content-container')?.classList.add('navClosed')
    }
  }, [expanded])

  useEffect(() => {
    const getImageDimensions = async () => {
      try {
        const dimensions: { width: number; height: number } = await new Promise(
          (resolve, reject) => {
            const image = new Image()
            image.onload = () => resolve({ width: image.width, height: image.height })
            image.onerror = reject
            image.src = organization?.logo ?? ''
          }
        )
        setIsSquareLogo(dimensions.width === dimensions.height)
      } catch {
        setIsSquareLogo(false)
      }
    }

    getImageDimensions()
  }, [organization])

  const onClickBrand = () => {
    track('sidenav app brand clicked', {
      product: 'CoderPad',
      version: 'xsell',
    })
    if (noRouter) {
      window.location.href = '/dashboard/pads'
    } else {
      history.push('/dashboard/pads')
    }
  }

  const onClickProductSwitcherTrialHomepageButton = useCallback(() => {
    window.location.replace(`${cgBaseUrl}/work/dashboard/trial-homepage/`)
  }, [])

  const company = organization
    ? [
        {
          name: organization.name,
          logo: organization.logo && isSquareLogo ? organization.logo : undefined,
          id: organization.id,
        },
      ]
    : []

  const productSwitcherItems = useProductSwitcherItems()

  const onExpandedChange = (expanded: boolean) => {
    track(`sidenav menu ${expanded ? 'expanded' : 'collapsed'}`, { version: 'xsell' })
    setExpanded(expanded)
  }

  const defaultSidebarProps = {
    verticalOffset,
    activeCompanyId: organization?.id,
    onChangeCompany: () => {},
    expanded,
    onExpandedChange,
    onBrandClick: onClickBrand,
    companies: company,
    showUpgrade: true,
    productSwitcherItems,
    onClickUpgrade: () => {
      // Note: Not using React Router because the upgrade route may or may
      // not go to a rails view, or in codingame, depending on user state
      track('sidenav upgrade clicked', { version: 'xsell' })
      window.location.href = upgradeRoute
    },
    onOpenCompanySwitcher: () => {
      track('sidenav team menu opened', { version: 'xsell' })
    },
    product: 'interview' as ProductType,
    onClickProductSwitcherTrialHomepageButton,
    labelTrialHomepageChips: `${trialChallengeUserProgress?.length}/${
      stepsAvailableLength[
        currentUser?.organization?.mainChallenge ?? currentUser?.currentProduct === 'INTERVIEW'
          ? OrganizationMainChallenge.MeetTechnicalGoals
          : OrganizationMainChallenge.SpeedUpHiring
      ]
    }`,
    showTrialHomepage:
      enableTrialHomepage &&
      currentPlan &&
      currentPlan.planCategorization === PlanCategorization.Free &&
      trialChallengeUserProgress &&
      trialChallengeUserProgress?.length > 0,
    labelTrialHomepageButton: 'Get started',
    isTrialHomepageSelected: false,
  }

  const sidebarItems = useSidebarItems(noRouter)

  return (
    <ScopedCssBaseline
      sx={{
        '& a:focus, & a:hover': { outline: 'none !important', textDecoration: 'none  !important' },
      }}
    >
      <Sidebar {...defaultSidebarProps} sidebarItems={sidebarItems} />
    </ScopedCssBaseline>
  )
}

export default SharedSidebar

import React, { createContext, useContext, useEffect, useState } from 'react'

import { useCurrentUser } from '../../../../../graphql/hooks'
import { QuestionVariant } from '../../../../../graphql/types'

export interface ActiveQuestionVariantContextContract {
  activeQuestionVariantId: number | null
  setActiveQuestionVariantId: (variantId: number) => void
}

export const activeQuestionVariantContext = createContext<ActiveQuestionVariantContextContract>({
  activeQuestionVariantId: null,
  setActiveQuestionVariantId: () => {},
})

interface ActiveQuestionVariantProviderProps {
  variants?: QuestionVariant[]
}

export const ActiveQuestionVariantProvider: React.FC<
  React.PropsWithChildren<ActiveQuestionVariantProviderProps>
> = ({ variants, children }) => {
  const [activeQuestionVariantId, setActiveQuestionVariantId] = useState<number | null>(null)
  const { currentUser } = useCurrentUser()
  const { defaultLanguage } = currentUser ?? {}

  useEffect(() => {
    if (activeQuestionVariantId == null && defaultLanguage != null) {
      let variant = variants?.find(
        (v) => v.language === defaultLanguage || v.projectTemplate?.slug === defaultLanguage
      )
      if (variant == null) {
        variant = variants?.find((v) => v.language === 'python3')
      }
      if (variant == null) {
        variant = variants?.[0]
      }
      if (variant != null) {
        setActiveQuestionVariantId(variant.id)
      }
    }
  }, [activeQuestionVariantId, defaultLanguage, variants])

  const ctxVal = {
    activeQuestionVariantId: activeQuestionVariantId,
    setActiveQuestionVariantId,
  }

  return (
    <activeQuestionVariantContext.Provider value={ctxVal}>
      {children}
    </activeQuestionVariantContext.Provider>
  )
}

export function useActiveQuestionVariant() {
  const contextVal = useContext(activeQuestionVariantContext)

  if (contextVal == null) {
    throw new Error(
      '`useActiveQuestionVariant` hook must be a descendant of a `ActiveQuestionVariantProvider`'
    )
  }

  return contextVal
}

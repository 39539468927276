import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { AppBar, Box, Button, Container, Link, Menu, MenuItem } from '@mui/material'
import clsx from 'classnames'
import { useHasEnterprisePlan } from 'packs/dashboard/Questions/hooks'
import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { checkInsightsEnabled } from 'utils/checkFeatureFlag'

import { ReactComponent as BellIcon } from '../../../../../assets/images/bell_icon.svg'
import { ReactComponent as CoderPadLogo } from '../../../../../assets/images/coderpad_logo_2020.svg'
import { ReactComponent as QuestionIcon } from '../../../../../assets/images/question_icon.svg'
import { Organization, User } from '../../../../graphql/types'
import { useFetch } from '../../../../utils/fetch/useFetch'
import { userBillingPageRoute } from '../../../../utils/routeHelpers'
import { track } from '../../../main/coderpad_analytics'
import { loadChatWidget } from '../../HubSpotChat/HubSpotChatUtils'
import { useStyles } from './Header.style'

interface IHeaderProps {
  currentUser?: User
  organization?: Organization
  noRouter?: boolean
}

export const Header: React.FC<React.PropsWithChildren<IHeaderProps>> = ({
  currentUser,
  organization,
  noRouter,
}) => {
  const styles = useStyles()
  const fetch = useFetch()
  const [menuOpenerEl, setMenuOpenerEl] = React.useState<null | HTMLElement>(null)
  const [docsMenuOpenerEl, setDocsMenuOpenerEl] = React.useState<null | HTMLElement>(null)

  const upgradeRoute = userBillingPageRoute(currentUser)
  const isEnterpriseUser = useHasEnterprisePlan()
  const isFreeUser = currentUser?.activeQuota.planObj?.title?.includes('Free')

  const setUserMenuOpenerEl = (el: null | HTMLElement) => {
    setMenuOpenerEl(el)
    track('topnav item clicked', { item: 'user menu', version: 'original' })
  }

  const setHelpMenuOpenerEl = (el: null | HTMLElement) => {
    setDocsMenuOpenerEl(el)
    track('topnav item clicked', { item: 'help menu', version: 'original' })
  }

  // Note that `currentUser.organizationOwner` is false if the user does not belong to have org.
  const hasAdminPrivs =
    currentUser != null && (currentUser?.organizationOwner || organization == null)
  // Parent and child organizations should have the Integrations and Billing links hidden.
  const isParentChildOrg =
    organization?.isParentOrganization ||
    organization?.childOrganizations?.length ||
    organization?.hasParentOrganization
  const isCodingameXSell = currentUser?.activeQuota?.planObj?.codingameXsellPlan ?? false

  const actionMenuItems = []

  if (
    !isCodingameXSell &&
    (hasAdminPrivs || organization?.padIndexEnabled) &&
    checkInsightsEnabled()
  ) {
    actionMenuItems.push(
      noRouter ? (
        <MenuItem
          component={Link}
          href="/dashboard/insights"
          key="insights"
          className={styles.headerActionMenuItem}
          onClick={() =>
            track('topnav user menu item clicked', { item: 'insights', variant: 'original' })
          }
        >
          Insights
        </MenuItem>
      ) : (
        <MenuItem
          component={RouterLink}
          to="/dashboard/insights"
          key="insights"
          className={styles.headerActionMenuItem}
          onClick={() =>
            track('topnav user menu item clicked', { item: 'insights', variant: 'original' })
          }
        >
          Insights
        </MenuItem>
      )
    )
  }
  if (hasAdminPrivs && !isParentChildOrg) {
    if (organization != null) {
      actionMenuItems.push(
        noRouter ? (
          <MenuItem
            component={Link}
            href="/dashboard/integrations"
            key="integrations"
            className={styles.headerActionMenuItem}
            onClick={() =>
              track('topnav user menu item clicked', {
                item: 'integrations',
                variant: 'original',
              })
            }
          >
            Integrations
          </MenuItem>
        ) : (
          <MenuItem
            component={RouterLink}
            to="/dashboard/integrations"
            key="integrations"
            className={styles.headerActionMenuItem}
            onClick={() =>
              track('topnav user menu item clicked', {
                item: 'integrations',
                variant: 'original',
              })
            }
          >
            Integrations
          </MenuItem>
        )
      )
    }
    actionMenuItems.push(
      noRouter ? (
        <MenuItem
          component={Link}
          href="/dashboard/billing"
          key="billing"
          className={styles.headerActionMenuItem}
          onClick={() =>
            track('topnav user menu item clicked', { item: 'billing', variant: 'original' })
          }
        >
          Billing
        </MenuItem>
      ) : (
        <MenuItem
          component={RouterLink}
          to="/dashboard/billing"
          key="billing"
          className={styles.headerActionMenuItem}
          onClick={() =>
            track('topnav user menu item clicked', { item: 'billing', variant: 'original' })
          }
        >
          Billing
        </MenuItem>
      )
    )
  }
  if (organization != null && hasAdminPrivs) {
    actionMenuItems.push(
      noRouter ? (
        <MenuItem
          component={Link}
          href="/dashboard/organization/settings"
          key="orgsettings"
          className={styles.headerActionMenuItem}
          onClick={() =>
            track('topnav user menu item clicked', { item: 'team settings', variant: 'original' })
          }
        >
          Team Settings
        </MenuItem>
      ) : (
        <MenuItem
          component={RouterLink}
          to="/dashboard/organization/settings"
          key="orgsettings"
          className={styles.headerActionMenuItem}
          onClick={() =>
            track('topnav user menu item clicked', { item: 'team settings', variant: 'original' })
          }
        >
          Team Settings
        </MenuItem>
      )
    )
  } else if (organization == null) {
    actionMenuItems.push(
      <MenuItem
        component={Link}
        href="/organization/new"
        key="createteam"
        className={styles.headerActionMenuItem}
        onClick={() =>
          track('topnav user menu item clicked', { item: 'create a team', variant: 'original' })
        }
      >
        Create a Team
      </MenuItem>
    )
  }
  if (!isEnterpriseUser) {
    actionMenuItems.push(
      <MenuItem
        className={clsx(styles.headerActionMenuItem, styles.upgradeActionMenuItem)}
        key="planupgrade"
        onClick={() =>
          track('Upgrade profile clicked', () => {
            window.location.href = upgradeRoute
          })
        }
      >
        Upgrade
      </MenuItem>
    )
  }
  actionMenuItems.push(<hr key="divider" />)
  actionMenuItems.push(
    noRouter ? (
      <MenuItem
        component={Link}
        href="/dashboard/settings"
        key="mysettings"
        className={styles.headerActionMenuItem}
        onClick={() =>
          track('topnav user menu item clicked', { item: 'my settings', variant: 'original' })
        }
      >
        My Settings
      </MenuItem>
    ) : (
      <MenuItem
        component={RouterLink}
        to="/dashboard/settings"
        key="mysettings"
        className={styles.headerActionMenuItem}
        onClick={() =>
          track('topnav user menu item clicked', { item: 'my settings', variant: 'original' })
        }
      >
        My Settings
      </MenuItem>
    )
  )
  actionMenuItems.push(
    <form action="/logout" method="post" key="logout">
      <MenuItem className={styles.headerActionMenuItem + ' ' + styles.headerActionMenuItemLogout}>
        <Button
          fullWidth={true}
          type="submit"
          classes={{ root: styles.headerActionMenuItemLogoutBtn }}
          onClick={() => {
            track('topnav user menu item clicked', { item: 'logout', variant: 'original' })
            window.localStorage.clear()
          }}
        >
          Logout
        </Button>
      </MenuItem>
      <input type="hidden" name="authenticity_token" value={fetch.authToken} />
      <input type="hidden" name="_method" value="delete" />
    </form>
  )

  const documentMenuItems = []
  if (currentUser != null) {
    documentMenuItems.push(
      <MenuItem
        component={Link}
        key="documentation"
        href="/resources/docs/"
        target="_blank"
        rel="noopener"
        className={styles.headerActionMenuItem}
        onClick={() =>
          track('topnav help menu item clicked', { item: 'docs', variant: 'original' })
        }
      >
        Documentation
      </MenuItem>
    )
    documentMenuItems.push(
      <MenuItem
        component={Link}
        key="gettingstarted"
        href="/resources/docs/how-to-use-coderpad/"
        target="_blank"
        rel="noopener"
        className={styles.headerActionMenuItem}
        onClick={() =>
          track('topnav help menu item clicked', { item: 'getting started', variant: 'original' })
        }
      >
        Getting Started
      </MenuItem>
    )
    documentMenuItems.push(
      <MenuItem
        component={Link}
        key="liveinterview"
        href="/resources/docs/live-interview-pads/"
        target="_blank"
        rel="noopener"
        className={styles.headerActionMenuItem}
        onClick={() =>
          track('topnav help menu item clicked', { item: 'setup live', variant: 'original' })
        }
      >
        Setting Up Live Interviews
      </MenuItem>
    )
    documentMenuItems.push(
      <MenuItem
        component={Link}
        key="takehomes"
        href="/resources/docs/take-home-pads/"
        target="_blank"
        rel="noopener"
        className={styles.headerActionMenuItem}
        onClick={() =>
          track('topnav help menu item clicked', { item: 'setup take-home', variant: 'original' })
        }
      >
        Setting Up Take-Homes
      </MenuItem>
    )
    documentMenuItems.push(
      <MenuItem
        component={Link}
        key="questionbank"
        href="/resources/docs/question-bank/"
        target="_blank"
        rel="noopener"
        className={styles.headerActionMenuItem}
        onClick={() =>
          track('topnav help menu item clicked', { item: 'questions', variant: 'original' })
        }
      >
        Question Bank
      </MenuItem>
    )
    documentMenuItems.push(
      <MenuItem
        component={Link}
        key="faqs"
        href="/faqs/"
        target="_blank"
        rel="noopener"
        className={styles.headerActionMenuItem}
        onClick={() =>
          track('topnav help menu item clicked', { item: 'faqs', variant: 'original' })
        }
      >
        FAQs
      </MenuItem>
    )
    documentMenuItems.push(
      <MenuItem
        component={Link}
        key="contactsupport"
        href={`/contact?email=${encodeURIComponent(currentUser.email)}`}
        className={styles.headerActionMenuItem}
        onClick={() =>
          track('topnav help menu item clicked', { item: 'support', variant: 'original' })
        }
      >
        Contact Support
      </MenuItem>
    )

    if (isFreeUser) {
      documentMenuItems.push(
        <MenuItem
          component={Link}
          key="live-help"
          onClick={() => loadChatWidget()}
          className={styles.headerActionMenuItem}
        >
          Live Help
        </MenuItem>
      )
    }
  }

  const onClickBrand = () => {
    track('sidenav app brand clicked', {
      product: 'CoderPad',
      version: 'original',
    })
  }

  return (
    <AppBar
      position="sticky"
      elevation={0}
      className={styles.headerWrap}
      data-testid="header"
      color="inherit"
      enableColorOnDark
    >
      <Box className={styles.header} height={72} display="flex" alignItems="center">
        <Container maxWidth={false}>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            {noRouter ? (
              <Link
                href="/dashboard"
                className={styles.headerLogoLink}
                underline="hover"
                onClick={onClickBrand}
              >
                <CoderPadLogo width={140} height={25} />
              </Link>
            ) : (
              <RouterLink to="/dashboard" className={styles.headerLogoLink} onClick={onClickBrand}>
                <CoderPadLogo width={140} height={25} />
              </RouterLink>
            )}
            <Box display="flex" alignItems="center">
              <Box
                onClick={(e) => setHelpMenuOpenerEl(e.currentTarget)}
                aria-controls="documentation-action-menu"
                className={styles.headerActionMenuOpener}
                data-testid="documentation-action-menu-opener"
                mr={5}
                display="flex"
                alignItems="center"
                pt={0.75}
                pb={0.75}
              >
                <QuestionIcon />
              </Box>
              <Box
                aria-controls="beamer-action-menu"
                className={clsx(styles.headerActionMenuOpener, 'beamerTrigger')}
                data-testid="beamer-action-menu-opener"
                mr={5}
                display="flex"
                alignItems="center"
                pt={0.75}
                pb={0.75}
              >
                <BellIcon />
              </Box>
              <Box
                onClick={(e) => setUserMenuOpenerEl(e.currentTarget)}
                aria-controls="header-action-menu"
                aria-haspopup={true}
                className={styles.headerActionMenuOpener}
                data-testid="header-action-menu-opener"
                display="flex"
                alignItems="center"
                pt={0.5}
                pb={0.5}
              >
                Hello{currentUser?.name ? `, ${currentUser.name}` : ''}{' '}
                <ExpandMoreIcon
                  className={clsx(styles.headerActionMenuIcon, { flip: !(menuOpenerEl == null) })}
                />
              </Box>
              <Menu
                MenuListProps={{ disablePadding: true }}
                id="header-action-menu"
                anchorEl={menuOpenerEl}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={!!menuOpenerEl}
                onClose={() => setUserMenuOpenerEl(null)}
                keepMounted
                variant="menu"
                autoFocus={false}
                onClick={() => setUserMenuOpenerEl(null)}
              >
                {actionMenuItems}
              </Menu>
              <Menu
                MenuListProps={{ disablePadding: true }}
                id="header-action-menu"
                anchorEl={docsMenuOpenerEl}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={!!docsMenuOpenerEl}
                onClose={() => setHelpMenuOpenerEl(null)}
                keepMounted
                variant="menu"
                autoFocus={false}
                onClick={() => setHelpMenuOpenerEl(null)}
              >
                {documentMenuItems}
              </Menu>
            </Box>
          </Box>
        </Container>
      </Box>
    </AppBar>
  )
}

import StarIcon from '@mui/icons-material/Star'
import StarOutlineIcon from '@mui/icons-material/StarOutline'
import { Box, Button } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'classnames'
import { User } from 'graphql/types'
import React from 'react'

import { userBillingPageRoute } from '../../../../utils/routeHelpers'
import { track } from '../../../main/coderpad_analytics'
import { useAppStateProperty } from '../AppState/AppStateProvider'

const useStyles = makeStyles((theme) => {
  return {
    navDisplay: {
      display: 'flex',
      justifyContent: 'center',
      position: 'sticky',
      bottom: 25,
    },
    menuDisplay: {
      width: '100%',
    },
    upgradeBtn: {
      width: '70%',
      backgroundColor: theme.palette.activation?.main,
      '&:hover': {
        backgroundColor: theme.palette.activation?.dark,
      },
    },
    upgradeIcon: {
      color: theme.palette.activation?.main,
      outline: 'none',
      '&:focus': {
        outline: 'none',
      },
    },
    drawerOpen: {
      transition: `${theme.transitions.duration.enteringScreen} ease-in-out`,
    },
    drawerClose: {
      transition: `${theme.transitions.duration.leavingScreen} ease-in`,
    },
  }
})

interface IPlanUpgradeDisplayProps {
  currentUser?: User
}

export const PlanUpgradeDisplay: React.FC<React.PropsWithChildren<IPlanUpgradeDisplayProps>> = ({
  currentUser,
}) => {
  const styles = useStyles()
  // Checks to see if the drawer is open or not
  const { value: isOpen } = useAppStateProperty('appNavOpen')
  const upgradeRoute = userBillingPageRoute(currentUser)

  return (
    <Box className={clsx(styles.navDisplay, styles.drawerOpen, styles.drawerClose)}>
      {isOpen ? (
        <Button
          className={styles.upgradeBtn}
          variant="contained"
          color="primary"
          size="large"
          endIcon={<StarOutlineIcon />}
          onClick={() =>
            track('Upgrade sidenav clicked', () => {
              window.location.href = upgradeRoute
            })
          }
        >
          Upgrade
        </Button>
      ) : (
        <IconButton
          className={styles.upgradeIcon}
          href={upgradeRoute}
          onClick={() =>
            track('Upgrade sidenav clicked', () => {
              window.location.href = upgradeRoute
            })
          }
          size="large"
        >
          <StarIcon fontSize="large" />
        </IconButton>
      )}
    </Box>
  )
}

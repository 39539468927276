import { Box, Slider, SvgIcon, Typography } from '@mui/material'
import React, { useContext, useMemo, useState } from 'react'

import { ControlIconGrid } from '../../ControlBars/ControlIconGrid'
import { ToolbarsContext } from '../../Whiteboard'
import { NoFill } from '../icons'
import { Palette } from '../icons'

const DARK_COLORS = [
  '#7BD4FD',
  '#FFFFFF',
  '#CACBCB',
  '#FC6E8D',
  '#F1A174',
  '#FDE68B',
  '#8DECB0',
  '#8AA5EF',
  '#A689FA',
]
const LIGHT_COLORS = [
  '#173CA5',
  '#000000',
  '#848587',
  '#E31A53',
  '#EA732F',
  '#FBBD23',
  '#199547',
  '#0085C9',
  '#6D28D9',
]

interface IColorPickerProps {
  currentColor?: string
  onChangeColor: (color: string, opacity?: number) => void
  onChangeOpacity?: (opacity: number) => void
  numCols?: number
  transparent?: boolean
  withOpacity?: boolean
}

export const ColorPicker: React.FC<React.PropsWithChildren<IColorPickerProps>> = ({
  currentColor,
  onChangeColor,
  onChangeOpacity,
  numCols = 3,
  transparent,
  withOpacity,
}) => {
  // If given a hex color with transparency e.g. #abcdef55 only take the first 7 characters #abcdef
  // or if given an empty/undefined string, set value to 'transparent'
  const [color, setColor] = useState(
    !currentColor || currentColor === '' ? 'transparent' : currentColor?.slice(0, 7)
  )
  // Parse characters 8 and 9 from a hex string, if present, to get the opacity of the current color
  const [opacity, setOpacity] = useState(
    Math.round(((parseInt(currentColor?.slice(7, 9) || 'ff', 16) ?? 255) / 255) * 100)
  )
  const { isDarkMode, drawingTheme } = useContext(ToolbarsContext)
  const colors = useMemo(() => {
    let colorSet = isDarkMode ? DARK_COLORS : LIGHT_COLORS
    if (transparent) {
      colorSet = ['transparent', ...colorSet]
    }
    return colorSet
  }, [isDarkMode, transparent])

  return (
    <Box sx={{ backgroundColor: drawingTheme.toolbarBg, borderRadius: 1 }}>
      {withOpacity && onChangeOpacity && (
        <Box sx={{ paddingX: 1, paddingY: 1 }}>
          <Slider
            aria-label="opacity"
            valueLabelDisplay="auto"
            value={opacity}
            onChange={(e, v) => setOpacity(v as number)}
            onChangeCommitted={(e, v) => onChangeOpacity(opacity / 100)}
            step={10}
            size="small"
            valueLabelFormat={(v) => `${v}%`}
            sx={{
              paddingTop: 1.5,
              paddingBottom: 0,
            }}
          />
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography sx={{ color: drawingTheme.toolbarColor, fontSize: '0.75rem' }}>
              Opacity
            </Typography>
            <Typography sx={{ color: drawingTheme.toolbarColor, fontSize: '0.75rem' }}>
              {opacity}%
            </Typography>
          </Box>
        </Box>
      )}
      <ControlIconGrid
        numCols={numCols}
        icons={colors.map((c) => ({
          Icon:
            c === 'transparent' ? <NoFill /> : <SvgIcon component={Palette} sx={{ color: c }} />,
          onClick: () => {
            setColor(c)
            onChangeColor(c, opacity / 100)
          },
          active: c === color,
        }))}
      />
    </Box>
  )
}

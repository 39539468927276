import { TabList as MUITabList, TabListProps as MUITabListProps } from '@mui/lab'
import { Box, TabProps as MUITabProps } from '@mui/material'
import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { TabsContextProvider } from './TabsContext'
import { TabSizes } from './types'

interface TabsProps {
  /** A prop that can be used to update this component's active tab state with another value. */
  value?: string
  /** Size of the tabs. Small medium or large. */
  size?: TabSizes
  /** Used to change the tab layout and move the tabs to the left of the content. */
  layout?: 'horizontal' | 'vertical'
  /** Specify this in conjunction with the `value` prop to make the Tabs a controlled component. */
  onSelectionChange?: (value: string) => void
  /** Provided for compatibility with the MUI `styled` utility. */
  className?: string
}

/**
 * Handy component to display a tabbed interface. This is mostly a styling wrapper over MUI tab components,
 * but with the added convenience of managing the state for the active tab for you.
 *
 * If you wish to use this tabs component as a controlled component, where you manage the state of the active tab,
 * this is possible using the `value` and `onSelectionChange` props. Simply pass in the `value` of the selected tab
 * to make that tab the active tab. `onSelectionChange` will be called with a tab value when the user selects a
 * different tab, allowing you to update your active tab value state.
 *
 * **Other Usage Notes**
 * The first child of `Tabs` must be a TabList component with Tabs as children. There is logic in the `Tabs` component
 * that preselects the first tab value when doing an uncontrolled `Tabs`. This is possible by reading the child tabs
 * of the tab list and getting the first childs value and using that as the initial state value for the active tab
 * value.
 */
export const Tabs: React.FC<React.PropsWithChildren<TabsProps>> = ({
  children,
  value,
  size,
  layout,
  onSelectionChange,
  className,
}) => {
  const childEls = useMemo(() => React.Children.toArray(children).filter((c) => !!c), [children])
  const tabListComp = childEls[0] as React.ReactElement<MUITabListProps>
  const tabPanels = useMemo(() => childEls.slice(1), [childEls])

  const tabs = React.Children.toArray(
    tabListComp.props.children
  ) as React.ReactElement<MUITabProps>[]

  const [activeTab, setActiveTab] = useState(onSelectionChange ? value : tabs[0].props.value)

  // Effect to listen to incoming changes to `value`, allowing a parent component to alter the active tab.
  useEffect(() => {
    if (value) {
      setActiveTab(value)
    }
  }, [value])

  const onChange = useCallback(
    (_e: any, newValue: string) => {
      if (onSelectionChange) {
        onSelectionChange(newValue)
      } else {
        setActiveTab(newValue)
      }
    },
    [onSelectionChange]
  )

  return (
    <TabsContextProvider value={{ size: size ?? 'medium', value: activeTab }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: layout === 'vertical' ? 'row' : 'column',
          flex: '1 1 auto',
          minHeight: 0,
          maxWidth: '100%',
        }}
        className={className}
      >
        <MUITabList
          {...tabListComp.props}
          onChange={onChange}
          // The smallest height necessary for small tabs.
          sx={{ minHeight: '26px', flex: 'none' }}
          orientation={layout === 'vertical' ? 'vertical' : 'horizontal'}
        >
          {tabs}
        </MUITabList>
        {tabPanels}
      </Box>
    </TabsContextProvider>
  )
}

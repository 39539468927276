import { CpChip } from 'packs/main/components/CpChip/CpChip'
import React from 'react'

import LanguageIcon from '../../../main/Monaco/FilePane/LanguageIcon'

interface ILanguageDisplayProps {
  display: string
  language: string
  onDelete?: () => void
  testId?: string
}

/**
 * Renders a Chip for a Language or Project Template, including its icon and name.
 * An onDelete handler can be passed to the component to allow the chips to be removed.
 */
export const LanguageDisplay: React.FC<React.PropsWithChildren<ILanguageDisplayProps>> = ({
  display,
  language,
  onDelete,
  testId,
}) => {
  return (
    <CpChip
      label={display}
      icon={<LanguageIcon language={language} />}
      onDelete={onDelete}
      data-testid={testId}
    />
  )
}

import { QueryState } from 'graphql/queryStates'
import { useCurrentUser } from '../../graphql/hooks/users/useCurrentUser/useCurrentUser'

export enum Permissions {
  MANAGE_USERS = 'manage_users',

  ACCESS_SCREENING = 'access_screening',
  ACCESS_CAMPAIGNS = 'access_campaigns',
  MANAGE_CAMPAIGNS = 'manage_campaigns',
  INVITE_CANDIDATES = 'invite_candidates',
  ACCESS_CANDIDATES = 'access_candidates',
  MANAGE_CANDIDATES = 'manage_candidates',
  ACCESS_DETAILED_REPORTS = 'access_detailed_reports',
  VIEW_WEBCAM_IMAGES = 'view_webcam_images',
  ACCESS_INSIGHTS = 'access_insights',
  VIEW_QUESTIONS = 'view_questions',
  MANAGE_QUESTIONS = 'manage_questions',

  ACCESS_SOURCING = 'access_sourcing',
  MANAGE_JOB_PROFILES = 'manage_job_profiles',

  ACCESS_ACCOUNT = 'access_account',
  ACCESS_USERS = 'access_users',
  INVITE_USERS = 'invite_users',
  MANAGE_TEAM_USERS = 'manage_team_users',
  EDIT_COMPANY = 'edit_company',
  ACCESS_BILLING = 'access_billing',
  MANAGE_DATA_PROTECTION = 'manage_data_protection',
  INTEGRATION = 'integration',

  ACCESS_MAP = 'access_map',
  ACCESS_MAP_CAMPAIGNS = 'access_map_campaigns',
  ACCESS_MAP_EMPLOYEES = 'access_map_employees',
  MANAGE_MAP_CAMPAIGNS = 'manage_map_campaigns',
  MANAGE_MAP_EMPLOYEES = 'manage_map_employees',

  ACCESS_INTERVIEW = 'access_interview',
}

interface UserPermissions {
  userHasPermission: (permission: Permissions) => boolean
  status: QueryState
}

export function useUserPermissions(): UserPermissions {
  const { currentUser, status } = useCurrentUser()

  const userHasPermission = (permission: Permissions) => {
    return currentUser?.permissions.includes(permission) === true
  }

  return {
    userHasPermission,
    status,
  }
}

import React, { FC, useContext } from 'react'

import { BringToFront } from '../../Toolbar/icons'
import { ToolbarsContext } from '../../Whiteboard'
import { ToolControlItem } from './ToolControlItem'

export const MoveSelectionForwardControl: FC<React.PropsWithChildren<unknown>> = () => {
  const { wbEngine } = useContext(ToolbarsContext)
  if (!wbEngine) return null

  return (
    <>
      <ToolControlItem
        tooltip="Bring Forward"
        onClick={() => {
          wbEngine.bringSelectionToFront()
        }}
      >
        <BringToFront />
      </ToolControlItem>
    </>
  )
}

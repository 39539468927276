import { Box, MenuItem, Select, SelectProps } from '@mui/material'
import LanguageIcon from 'packs/main/Monaco/FilePane/LanguageIcon'
import React from 'react'

import {
  LanguageAndProjectTemplateListItem,
  useLanguageAndProjectTemplateList,
} from '../../../graphql/hooks/useLanguageAndProjectTemplateList/useLanguageAndProjectTemplateList'
import * as queryStates from '../../../graphql/queryStates'

export type LanguageSelectProps = SelectProps & {
  whitelist?: string[]
}

export const LanguageSelect: React.FC<React.PropsWithChildren<LanguageSelectProps>> = function ({
  whitelist,
  ...props
}) {
  const { list, status } = useLanguageAndProjectTemplateList({ includeEmptyOption: false })

  return (
    <Select
      disabled={queryStates.isLoadingState(status)}
      variant="outlined"
      margin="dense"
      size="small"
      {...props}
    >
      {!whitelist && <MenuItem value="any">Any language</MenuItem>}
      {list.map((item: LanguageAndProjectTemplateListItem) =>
        !whitelist || whitelist.includes(item.value) ? (
          <MenuItem value={item.value} key={item.value}>
            <Box display="flex" alignItems="center">
              <LanguageIcon height={18} width={18} language={item.value} />
              <Box pl={1}>{item.label}</Box>
            </Box>
          </MenuItem>
        ) : null
      )}
    </Select>
  )
}

import { SvgIcon } from '@mui/material'
import React, { FC, useCallback, useContext, useEffect, useState } from 'react'

import { ColorPicker } from '../../Toolbar/ColorPicker/ColorPicker'
import { FillColor } from '../../Toolbar/icons'
import { ToolbarsContext } from '../../Whiteboard'
import { ToolControlItem } from './ToolControlItem'

export const FillColorControl: FC<React.PropsWithChildren<unknown>> = () => {
  const { setDefaultShapeFillColor, setDefaultShapeFillOpacity, wbEngine } = useContext(
    ToolbarsContext
  )
  const [currentColor, setCurrentColor] = useState('inherit')

  useEffect(() => {
    setCurrentColor(wbEngine?.selectedObjects[0]?.objects?.[0]?.fill?.slice(0, 7) ?? 'inherit')
  }, [wbEngine?.selectedObjects])

  const handleColorSelection = useCallback(
    (color: any, opacity: any) => {
      wbEngine!.setOnSelection({ fillColor: color, fillOpacity: opacity })
      setCurrentColor(color)
      setDefaultShapeFillColor(color)
      setDefaultShapeFillOpacity(opacity)
    },
    [setDefaultShapeFillColor, setDefaultShapeFillOpacity, wbEngine]
  )

  const handleOpacitySelection = useCallback(
    (opacity: any) => {
      wbEngine!.setOnSelection({ fillOpacity: opacity })
      setDefaultShapeFillOpacity(opacity)
    },
    [setDefaultShapeFillOpacity, wbEngine]
  )

  if (!wbEngine) return null

  return (
    <>
      <ToolControlItem
        tooltip="Fill Color"
        popperMenu={({ open, setOpen }) => (
          <ColorPicker
            onChangeColor={handleColorSelection}
            onChangeOpacity={handleOpacitySelection}
            currentColor={wbEngine.selectedObjects[0]?.objects?.[0]?.fill}
            transparent={true}
            numCols={5}
            withOpacity={true}
          />
        )}
      >
        <SvgIcon
          component={FillColor}
          sx={{
            '#colorBar': {
              color: currentColor,
            },
          }}
        />
      </ToolControlItem>
    </>
  )
}

import { WhiteboardEngine } from '@codinpad/wbengine-client'
import { Box, Theme, useTheme } from '@mui/material'
import * as Sentry from '@sentry/browser'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFetch } from 'utils/fetch/useFetch'

import { track } from '../../../main/coderpad_analytics'
import { ErrorBoundary } from '../ErrorBoundary/ErrorBoundary'
import { GenericErrorView } from '../GenericErrorView/GenericErrorView'
import { ClearBoardConfirmation } from './ClearBoardConfirmation/ClearBoardConfirmation'
import { ConnectionStatus } from './ConnectionStatus/ConnectionStatus'
import { CorrectionControls } from './ControlBars/CorrectionControls'
import { DarkLightModeSelector } from './ControlBars/DarkLightModeSelector'
import SelectionControlBar from './ControlBars/SelectionControlBar'
import { ViewportControls } from './ControlBars/ViewportControls'
import { ImageForm } from './ImageForm/ImageForm'
import { Toolbar } from './Toolbar/Toolbar'
import { useResizeHandler } from './useResizeHandler'
import {
  initWBEngine,
  IWhiteboardProps,
  MessagedError,
  seedObjectsToSandboxBoard,
  ToolOptions,
  toolToWBMode,
} from './util'

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const DEFAULT_TOOL_OPTIONS = {
  shape: {
    stroke: '#666666',
    fill: '',
    fillOpacity: null,
  },
  text: {
    stroke: '#666666',
    fill: '#666666',
    fontFamily: 'Arial',
    fontSize: 48,
    fontStyle: {
      fontWeight: 'normal',
      fontStyle: 'normal',
      isUnderlined: false,
      isStrikethrough: false,
    },
  },
  line: {
    stroke: '#666666',
    strokeWidth: 2,
  },
}

// The pad view now has its own ThemeProvider that supports light/dark mode. Until drawing mode is updated for dark
// mode, wrap it with the dashboard's theme provider (this is what it had before the pad view had its own theme).
// i.e. fixes funky looking image upload form when in dark mode
export const Whiteboard: React.FC<React.PropsWithChildren<IWhiteboardProps>> = (props) => {
  // TODO cleanup. I unceremoniously removed the nested theme provider, so we don't actually need the intermediary component.
  return <_Whiteboard {...props} />
}

interface IToolbarsContext {
  isDarkMode: boolean
  drawingTheme: {
    bg: string
    toolbarBg: string
    toolbarColor: string
    toolbarColorDisabled: string
    modeSelected: string
    modeHovered: string
  }
  wbEngine: WhiteboardEngine | null
  setDefaultShapeBorderColor: (borderColor: string) => void
  setDefaultShapeFillColor: (fillColor: string) => void
  setDefaultShapeFillOpacity: (opacity: number) => void
  setDefaultFontColor: (textColor: string) => void
  setDefaultFontSize: (textSize: number) => void
  setDefaultFontFamily: (font: string) => void
  setDefaultFontStyle: (option: string, value: string | boolean) => void
  setDefaultLineColor: (color: string) => void
  setDefaultLineWidth: (width: number) => void
}

export const ToolbarsContext = React.createContext<IToolbarsContext>({
  isDarkMode: true,
  drawingTheme: {
    bg: '#1E2126',
    toolbarBg: '#282C32',
    toolbarColor: '#FFFFFF',
    toolbarColorDisabled: '#777D8C',
    modeSelected: '#696A6D',
    modeHovered: '#3D434D',
  },
  wbEngine: null,
  setDefaultShapeBorderColor: (color) => null,
  setDefaultShapeFillColor: (color) => null,
  setDefaultShapeFillOpacity: (opacity) => null,
  setDefaultFontColor: (color) => null,
  setDefaultFontSize: (size) => null,
  setDefaultFontFamily: (font) => null,
  setDefaultFontStyle: (option, value) => null,
  setDefaultLineColor: (color) => null,
  setDefaultLineWidth: (width) => null,
})

export const _Whiteboard: React.FC<React.PropsWithChildren<IWhiteboardProps>> = ({
  allowInit = true,
  drawingBoardId,
  authorId,
  isSandbox,
  sandboxDrawingBoardId,
  slug,
}) => {
  const theme = useTheme()
  const [wbEngine, setWBEngine] = useState<WhiteboardEngine | null>(null)
  const canvasEl = useRef<HTMLCanvasElement>(null)
  const containerEl = useRef<HTMLDivElement>(null)
  const [activeTool, setActiveTool] = useState('pencil')
  // the tool to return to when temporarily using another tool e.g. holding space to pan
  const [prevTool, setPrevTool] = useState<string>()
  const [isSelectingImage, setIsSelectingImage] = useState(false)
  const [isConnected, setIsConnected] = useState(false)
  const [isConnecting, setIsConnecting] = useState(false)
  const [isConfirmingClear, setIsConfirmingClear] = useState(false)
  const [canRedo, setCanRedo] = useState(false)
  const [canUndo, setCanUndo] = useState(false)
  const [zoomScale, setZoomScale] = useState(1) // Default to 100% zoom.
  const [isGridOn, setIsGridOn] = useState(false)
  const fetch = useFetch()
  const [isDarkMode, setIsDarkMode] = useState(true)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const selectionControlsEl = useRef<HTMLDivElement>(null)
  const [selection, setSelection] = useState<any>()
  const [defaultToolOptions, setDefaultToolOptions] = useState<ToolOptions>(DEFAULT_TOOL_OPTIONS)
  const [selectionControlsPosition, setSelectionControlsPosition] = useState({ top: 16, left: 80 })
  const dispatch = useDispatch()

  const { userName, userColor } = useSelector((state) => {
    let userName = ''
    let userColor = '#36BFF9'

    if (state?.userState) {
      const me = Object.values<any>(state.userState.userInfo).find((user) => user.self)
      userName = me ? me.name : ''
      userColor = state.userState.userInfo[state.userState.userId].color
    }

    return { userName, userColor }
  })

  useEffect(() => {
    if (userColor && wbEngine) {
      setDefaultToolOptions((toolOptions) => {
        const options = {
          shape: {
            ...toolOptions.shape,
            stroke: userColor,
          },
          text: {
            ...toolOptions.text,
            stroke: userColor,
            fill: userColor,
          },
          line: {
            ...toolOptions.line,
            stroke: userColor,
          },
        }
        // Default tool is pencil, update its options once user color is known
        if (wbEngine.currentMode === 'free') {
          wbEngine.setToolOptions(options.line)
        }
        return options
      })
      // use a timeout to avoid a race condition with the initialization of wbEngine,
      // which may not have the latest value for userColor
      setTimeout(() => wbEngine.updateUserData({ color: userColor }), 1)
    }
  }, [userColor, wbEngine])

  const zoomIn = useCallback(() => wbEngine?.setZoom(wbEngine.zoom / 0.95), [wbEngine])
  const zoomOut = useCallback(() => wbEngine?.setZoom(wbEngine.zoom * 0.95), [wbEngine])
  const zoomReset = useCallback(() => wbEngine?.setZoom(1), [wbEngine])

  const drawingTheme = useMemo(() => theme.palette.drawing[isDarkMode ? 'dark' : 'light'], [
    isDarkMode,
    theme,
  ])

  const setDMTool = useCallback(
    (toolName: string) => {
      setActiveTool(toolName)

      const { mode, toolOptions } = toolToWBMode(toolName, defaultToolOptions)
      if (mode) {
        wbEngine?.clearSelection()
        wbEngine?.setMode(mode)
        wbEngine?.setToolOptions(toolOptions)
      }
    },
    [defaultToolOptions, wbEngine]
  )

  const setDefaultShapeBorderColor = useCallback((borderColor: any) => {
    setDefaultToolOptions((toolOptions) => ({
      ...toolOptions,
      shape: {
        ...toolOptions.shape,
        stroke: borderColor,
      },
    }))
  }, [])

  const setDefaultShapeFillColor = useCallback((fillColor: any) => {
    setDefaultToolOptions((toolOptions) => ({
      ...toolOptions,
      shape: {
        ...toolOptions.shape,
        fill: fillColor,
      },
    }))
  }, [])

  const setDefaultShapeFillOpacity = useCallback((fillOpacity: any) => {
    setDefaultToolOptions((toolOptions) => ({
      ...toolOptions,
      shape: {
        ...toolOptions.shape,
        fillOpacity: fillOpacity,
      },
    }))
  }, [])

  const setDefaultFontColor = useCallback((textColor: any) => {
    setDefaultToolOptions((toolOptions) => ({
      ...toolOptions,
      text: {
        ...toolOptions.text,
        stroke: textColor,
        fill: textColor,
      },
    }))
  }, [])

  const setDefaultFontSize = useCallback((textSize: any) => {
    setDefaultToolOptions((toolOptions) => ({
      ...toolOptions,
      text: {
        ...toolOptions.text,
        fontSize: textSize,
      },
    }))
  }, [])

  const setDefaultFontFamily = useCallback((font: any) => {
    setDefaultToolOptions((toolOptions) => ({
      ...toolOptions,
      text: {
        ...toolOptions.text,
        fontFamily: font,
      },
    }))
  }, [])

  const setDefaultFontStyle = useCallback((option: any, value: any) => {
    setDefaultToolOptions((toolOptions) => ({
      ...toolOptions,
      text: {
        ...toolOptions.text,
        fontStyle: {
          ...toolOptions.text.fontStyle,
          [option]: value,
        },
      },
    }))
  }, [])

  const setDefaultLineColor = useCallback((color: any) => {
    setDefaultToolOptions((toolOptions) => ({
      ...toolOptions,
      line: {
        ...toolOptions.line,
        stroke: color,
      },
    }))
  }, [])

  const setDefaultLineWidth = useCallback((width: any) => {
    setDefaultToolOptions((toolOptions) => ({
      ...toolOptions,
      line: {
        ...toolOptions.line,
        strokeWidth: width,
      },
    }))
  }, [])

  const calcSelectionControlsPosition = useCallback(
    (selection: any) => {
      const verticalOffset = 60
      if (!selection || !selectionControlsEl.current || !canvasEl.current) {
        return
      }

      const canvas = canvasEl.current
      const selectionControls = selectionControlsEl.current
      const bounds = selection.getBoundingRect()

      let left = bounds.left + bounds.width / 2 - selectionControls.offsetWidth / 2
      left = Math.max(
        canvas.offsetLeft,
        Math.min(canvas.offsetLeft + canvas.offsetWidth - selectionControls.offsetWidth, left)
      )

      let top = bounds.top - selectionControls.offsetHeight - verticalOffset
      if (top < 0) {
        top = bounds.top + bounds.height + verticalOffset
      }

      setSelectionControlsPosition({ left, top })
    },
    [selectionControlsEl]
  )

  const onSelectionChanged = useCallback(
    (data: any) => {
      setSelection(data.activeObject)
      calcSelectionControlsPosition(data.activeObject)
    },
    [calcSelectionControlsPosition]
  )

  const updateSelectionControlPosition = useCallback(
    (wbEngine: any) => {
      const selection = wbEngine?.getSelectionObject()
      if (selection) {
        calcSelectionControlsPosition(selection)
      }
    },
    [calcSelectionControlsPosition]
  )

  // Key handling fn + effect to attach to the document.
  const keyDownHandler = useCallback(
    (event: KeyboardEvent) => {
      event.stopPropagation()
      if ((event.key === 'Backspace' || event.key === 'Delete') && activeTool === 'select') {
        wbEngine?.removeSelectedObjects()
      } else if (
        (event.key === 'z' || event.key === 'Z') &&
        (event.ctrlKey || event.metaKey) &&
        event.shiftKey
      ) {
        wbEngine?.redo()
      } else if (event.key === 'z' && (event.ctrlKey || event.metaKey)) {
        wbEngine?.undo()
      }

      if (event.key === 'c' && (event.ctrlKey || event.metaKey)) {
        wbEngine?.copySelection()
      } else if (event.key === 'v' && (event.ctrlKey || event.metaKey)) {
        wbEngine?.paste()
      }

      if (event.key === '+' && (event.ctrlKey || event.metaKey)) {
        zoomIn()
        event.preventDefault()
      } else if (event.key === '-' && (event.ctrlKey || event.metaKey)) {
        zoomOut()
        event.preventDefault()
      }

      if ((!prevTool && event.key === ' ') || event.code.startsWith('Alt')) {
        setPrevTool(activeTool)
        setDMTool('pan')
      }
    },
    [activeTool, wbEngine, zoomIn, zoomOut, setDMTool, prevTool]
  )

  const keyUpHandler = useCallback(
    (event: KeyboardEvent) => {
      event.stopPropagation()

      if (prevTool && (event.key === ' ' || event.code.startsWith('Alt'))) {
        setDMTool(prevTool)
        setPrevTool(undefined)
      }
    },
    [prevTool, setDMTool]
  )

  useEffect(() => {
    containerEl.current?.addEventListener('keydown', keyDownHandler, false)
    containerEl.current?.addEventListener('keyup', keyUpHandler, false)
    return () => {
      containerEl.current?.removeEventListener('keydown', keyDownHandler)
      containerEl.current?.removeEventListener('keyup', keyUpHandler)
    }
  }, [keyDownHandler, keyUpHandler])

  // Effect to update the user's name.
  useEffect(() => {
    wbEngine?.updateUserData({ name: userName })
  }, [userName, wbEngine])

  // Effect to create an instance of WBEngine.
  const setupWBEngine = useCallback(() => {
    if (wbEngine) return wbEngine

    const wb = initWBEngine({
      sandbox: isSandbox ?? true,
      canvasElement: canvasEl.current!,
      setIsConnected,
    })

    wb.setMode('free')
    wb.on('undo:change', (data: any) => {
      setCanUndo(!!data?.canUndo)
      setCanRedo(!!data?.canRedo)
    })
    wb.on('panzoom', () => setZoomScale(Math.round(wb.zoom * 100) / 100)) // *100/100 to get rid of floating point imprecisions
    wb.on('object:added', () => dispatch({ type: 'drawing_modified' }))
    wb.on('wbengine:shapeTextEdited', () => {
      track('Whiteboard shape text edited', {})
    })
    wb.on('selection:created', onSelectionChanged)
    wb.on('selection:updated', onSelectionChanged)
    wb.on('selection:cleared', onSelectionChanged)
    wb.on('object:modified', () => {
      // When selecting multiple objects, the selection is empty for one frame after dragging it
      setTimeout(() => updateSelectionControlPosition(wb))
    })

    // After drawing a shape or text, the tool is switched (by wbEngine) to the select tool, so
    // update our active tool so that change is shown in the toolbar
    wb.on('mode:changed', (mode: string) => {
      if (mode === 'select') {
        setActiveTool(mode)
      }
    })

    return wb
  }, [wbEngine, isSandbox, dispatch, onSelectionChanged, updateSelectionControlPosition])

  // Handler + effect to resize the WBEngine canvas when the window size changes.
  useResizeHandler(wbEngine)

  // Callback to get the drawing board id and connect to the Drawing backend.
  const connect = useCallback(async () => {
    if (isConnecting || isConnected) {
      return
    }
    const wbEngine = setupWBEngine()
    setWBEngine(wbEngine)

    setIsConnecting(true)
    try {
      let boardId: string
      if (isSandbox) {
        // Sandbox pads do not get a board id. They run in "sandbox" mode of WBEngine and do not actually connect
        // to the backend.
        boardId = ''
      } else if (drawingBoardId) {
        // Already have a drawing board id, no need to request a new one.
        boardId = drawingBoardId
      } else {
        boardId = (await (await fetch(`/${slug}/drawing`, { method: 'post' })).json()).boardId
        window.padConfig!.drawingBoardId = boardId
      }

      // Don't need to chain onto wbEngine.connect. The event handlers attached during creation of wbEngine will
      // handle setting states after this point.
      await wbEngine?.connect(boardId, authorId, {
        name: userName,
        color: userColor,
      })

      // will seed the sandbox board with objects from the drawing question
      if (isSandbox && sandboxDrawingBoardId) {
        await seedObjectsToSandboxBoard(wbEngine, sandboxDrawingBoardId)
      }
    } catch (error: unknown) {
      setIsConnected(false)
      Sentry.captureMessage(
        `Drawing initial connection failure: ${(error as MessagedError).message}`,
        {
          level: 'error' as Sentry.SeverityLevel,
          tags: { layer: 'react', feature: 'drawing_mode' },
          extra: {
            error,
          },
        }
      )
    } finally {
      setIsConnecting(false)
    }
  }, [
    isConnecting,
    isConnected,
    setupWBEngine,
    isSandbox,
    drawingBoardId,
    authorId,
    userName,
    userColor,
    sandboxDrawingBoardId,
    fetch,
    slug,
  ])

  // Effect to trigger the initial connection to the Drawing service.
  useEffect(() => {
    if (allowInit) {
      connect()
    }
  }, [allowInit, connect])

  const clearBoard = useCallback(() => {
    wbEngine?.removeAllObjects()
    setIsConfirmingClear(false)
  }, [wbEngine])

  const addImage = useCallback(
    async (imgUrl: string) => {
      if (wbEngine) {
        const imgObj = await wbEngine.addImageFromURL(imgUrl)
        if (imgObj) {
          wbEngine.clearSelection()
          wbEngine.addToSelection([imgObj])
          setDMTool('select')
        }
      }
    },
    [wbEngine, setDMTool]
  )

  return (
    <Box ref={containerEl} tabIndex={-1} sx={{ height: '100%', background: drawingTheme.bg }}>
      <ErrorBoundary fallback={(e) => <GenericErrorView error={e} />}>
        <Box height="100%" sx={{ userSelect: 'none' }}>
          <ImageForm
            isOpen={isSelectingImage}
            container={containerEl.current || undefined}
            onRequestClose={() => setIsSelectingImage(false)}
            onLinkSubmit={addImage}
            slug={slug ? slug : ''}
          />
          {!isSandbox && (
            <Box position="absolute" width="100%" zIndex={1}>
              <ConnectionStatus
                isConnected={isConnected}
                isConnecting={isConnecting}
                handleConnectionRetry={connect}
              />
            </Box>
          )}
          <ClearBoardConfirmation
            isOpen={isConfirmingClear}
            onConfirmClear={clearBoard}
            onCancel={() => setIsConfirmingClear(false)}
            container={containerEl.current || undefined}
          />
          <ToolbarsContext.Provider
            value={{
              isDarkMode,
              drawingTheme,
              wbEngine,
              setDefaultShapeBorderColor,
              setDefaultShapeFillColor,
              setDefaultShapeFillOpacity,
              setDefaultFontColor,
              setDefaultFontSize,
              setDefaultFontFamily,
              setDefaultFontStyle,
              setDefaultLineColor,
              setDefaultLineWidth,
            }}
          >
            <Box position="absolute" zIndex={1} left={16} top={16}>
              <Toolbar
                setTool={setDMTool}
                activeTool={activeTool}
                selectImage={() => setIsSelectingImage(true)}
              />
            </Box>
            <Box
              ref={selectionControlsEl}
              position="absolute"
              zIndex={1}
              left={selectionControlsPosition.left}
              top={selectionControlsPosition.top}
            >
              <SelectionControlBar selection={selection} />
            </Box>
            <Box
              sx={{
                position: 'absolute',
                bottom: theme.spacing(2),
                left: theme.spacing(2),
                width: `calc(100% - ${theme.spacing(4)})`,
                zIndex: 1,
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  columnGap: theme.spacing(1),
                }}
              >
                <CorrectionControls
                  setTool={setDMTool}
                  undo={() => wbEngine?.undo()}
                  redo={() => wbEngine?.redo()}
                  canRedo={canRedo}
                  canUndo={canUndo}
                  isClearModeOpen={isConfirmingClear}
                  toggleConfirmClearMode={() => setIsConfirmingClear(!isConfirmingClear)}
                />
                <ViewportControls
                  zoomIn={zoomIn}
                  zoomOut={zoomOut}
                  zoomReset={zoomReset}
                  defaultZoomLevel={1}
                  zoomLevel={zoomScale}
                  setTool={setDMTool}
                  activeTool={activeTool}
                  fitContentToScreen={() => wbEngine?.zoomToFit()}
                  toggleGrid={() => setIsGridOn(wbEngine?.toggleGrid() ?? false)}
                  isGridOn={isGridOn}
                />
              </Box>
              <DarkLightModeSelector toggleDarkMode={setIsDarkMode} />
            </Box>
          </ToolbarsContext.Provider>
          <canvas ref={canvasEl}></canvas>
        </Box>
      </ErrorBoundary>
    </Box>
  )
}

import { Box, styled, SvgIcon } from '@mui/material'
import React, { FC } from 'react'

import { ReactComponent as LinkedInIcon } from '../../../../../assets/images/linkedin.svg'
import { ReactComponent as TwitterIcon } from '../../../../../assets/images/twitter.svg'

const FooterLink = styled('a')({
  color: '#fff',
  fontSize: '0.875rem',
  padding: '0 8px',
  display: 'block',
})

const Footer: FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <Box
      sx={{
        px: 12.5,
        py: 3,
        display: 'flex',
        backgroundColor: '#b8bfca',
      }}
    >
      <Box
        sx={{
          flex: 1,
          flexDirection: 'column',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            marginBottom: 2,
            justifyContent: 'flex-start',
          }}
        >
          <SvgIcon
            component="a"
            href="https://twitter.com/coderpad"
            sx={{ width: '22px', height: '22px', paddingRight: 1 }}
          >
            <TwitterIcon />
          </SvgIcon>
          <SvgIcon
            component="a"
            href="https://www.linkedin.com/company/coderpad"
            sx={{ width: '22px', height: '22px' }}
          >
            <LinkedInIcon />
          </SvgIcon>
        </Box>
        <Box sx={{ color: '#fff', fontSize: '0.875rem' }}>
          © {new Date().getFullYear()} CoderPad, Inc. CoderPad is a service mark of CoderPad, Inc.
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flex: 1,
          flexWrap: 'wrap',
          justifyContent: 'flex-end',
          maxWidth: '416px',
        }}
      >
        <FooterLink href="/about-us" target="_blank">
          About Us
        </FooterLink>
        <FooterLink href="/pricing" target="_blank">
          Pricing
        </FooterLink>
        <FooterLink href="/features" target="_blank">
          Features
        </FooterLink>
        <FooterLink href="/docs" target="_blank">
          Docs
        </FooterLink>
        <FooterLink href="/tos" target="_blank">
          Terms of Service
        </FooterLink>
        <FooterLink href="https://status.coderpad.io/" target="_blank" rel="noreferrer">
          Status
        </FooterLink>
        <FooterLink href="mailto:support@coderpad.io?subject=Question%20About%20CoderPad">
          Questions? support@coderpad.io
        </FooterLink>
        <FooterLink href="/privacy" target="_blank">
          Privacy Policy
        </FooterLink>
      </Box>
    </Box>
  )
}

export default Footer

import { ToggleButton, ToggleButtonGroup } from '@mui/material'
import React, { useCallback, useContext, useMemo } from 'react'

import { ToolbarsContext } from '../Whiteboard'
import { ControlBar } from './ControlBar'

interface IDarkLightModeSelectorProps {
  toggleDarkMode: (isDark: boolean) => void
}

export const DarkLightModeSelector: React.FC<
  React.PropsWithChildren<IDarkLightModeSelectorProps>
> = ({ toggleDarkMode }) => {
  const { isDarkMode, drawingTheme } = useContext(ToolbarsContext)

  const handleModeToggle = useCallback(
    (e: any) => {
      toggleDarkMode(e.target.value === 'true')
    },
    [toggleDarkMode]
  )

  const buttonStyle = useMemo(
    () => ({
      color: drawingTheme.toolbarColor,
      '&.Mui-selected, &.Mui-selected:hover': {
        backgroundColor: drawingTheme.modeSelected,
        color: drawingTheme.toolbarColor,
      },
      '&:hover': {
        backgroundColor: drawingTheme.modeHovered,
      },
    }),
    [drawingTheme]
  )

  return (
    <ControlBar
      items={[
        <ToggleButtonGroup
          key="modeToggle"
          value={isDarkMode}
          exclusive
          onChange={handleModeToggle}
          sx={{ height: '100%' }}
        >
          <ToggleButton value={true} sx={buttonStyle}>
            Dark
          </ToggleButton>
          <ToggleButton value={false} sx={buttonStyle}>
            Light
          </ToggleButton>
        </ToggleButtonGroup>,
      ]}
    />
  )
}

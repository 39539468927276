import { styled, Tab as MUITab, TabProps as MUITabProps } from '@mui/material'
import React, { useContext } from 'react'

import { TabsContext } from './TabsContext'

interface TabProps extends MUITabProps {
  cta?: boolean
}
const EnhancedTab = styled(MUITab, {
  shouldForwardProp: (prop) => prop !== 'cta',
})<TabProps>(({ cta }) => {
  return cta
    ? {
        color: '#3794ff',
        opacity: 1,
      }
    : {}
})
const SmallTab = styled(EnhancedTab)({
  padding: '6px 10px',
  fontSize: '0.8125rem',
  minHeight: '26px',
  minWidth: '40px',
})
SmallTab.displayName = 'SmallTab'

const LargeTab = styled(EnhancedTab)({
  padding: '10px 12px',
  fontSize: '1rem',
  minHeight: '60px',
})
LargeTab.displayName = 'LargeTab'

export const Tab: React.FC<React.PropsWithChildren<TabProps>> = (props) => {
  const { size } = useContext(TabsContext)
  const TabComp = size === 'small' ? SmallTab : size === 'large' ? LargeTab : EnhancedTab

  return <TabComp {...props} />
}

import {
  Box,
  ClickAwayListener,
  Popper,
  PopperProps as _PopperProps,
  styled,
  SxProps,
  Tooltip,
} from '@mui/material'
import React, { FC, ReactNode, useCallback, useContext, useRef, useState } from 'react'

import { ToolbarsContext } from '../../Whiteboard'

const Container = styled(Box)({
  height: '40px',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '0 12px',
})

interface ChildFunctionProps {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
}

interface ToolControlItemProps {
  tooltip?: string
  onClick?: () => void
  sx?: SxProps
  popperMenu?: (props: ChildFunctionProps) => ReactNode
}

export const ToolControlItem: FC<React.PropsWithChildren<ToolControlItemProps>> = ({
  children,
  popperMenu,
  onClick,
  tooltip,
  sx,
}) => {
  const [open, setOpen] = useState(false)
  const ref = useRef()
  const { drawingTheme } = useContext(ToolbarsContext)

  const handleClickAway = () => {
    setOpen(false)
  }

  const handleClick = useCallback(() => {
    if (onClick) {
      onClick()
    }
    if (popperMenu) {
      setOpen((open) => !open)
    }
  }, [popperMenu, setOpen, onClick])

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      {/* Must be able to hold a ref, so cannot be a fragment */}
      <div>
        <Tooltip title={tooltip} placement={'bottom'} enterDelay={750}>
          <Container
            ref={ref}
            onClick={handleClick}
            color={drawingTheme.toolbarColor}
            sx={{ cursor: 'pointer', ...sx }}
          >
            {children}
          </Container>
        </Tooltip>
        {popperMenu && (
          <Popper
            sx={{ zIndex: 1300, paddingTop: 1 }}
            open={open}
            anchorEl={ref.current}
            placement="bottom-start"
          >
            <Box sx={{ backgroundColor: drawingTheme.toolbarBg, borderRadius: 1 }}>
              {popperMenu({ open, setOpen })}
            </Box>
          </Popper>
        )}
      </div>
    </ClickAwayListener>
  )
}

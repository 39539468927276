import { SvgIcon } from '@mui/material'
import React, { FC, useCallback, useContext, useEffect, useState } from 'react'

import { ColorPicker } from '../../Toolbar/ColorPicker/ColorPicker'
import { BorderColor } from '../../Toolbar/icons'
import { ToolbarsContext } from '../../Whiteboard'
import { ToolControlItem } from './ToolControlItem'

export const BorderColorControl: FC<React.PropsWithChildren<unknown>> = () => {
  const { setDefaultShapeBorderColor, wbEngine } = useContext(ToolbarsContext)
  const [currentColor, setCurrentColor] = useState('inherit')

  useEffect(() => {
    setCurrentColor(wbEngine?.selectedObjects[0]?.objects?.[0]?.stroke?.slice(0, 7) ?? 'inherit')
  }, [wbEngine?.selectedObjects])

  const handleColorSelection = useCallback(
    (color: any) => {
      wbEngine!.setOnSelection({ stroke: color })
      setCurrentColor(color)
      setDefaultShapeBorderColor(color)
    },
    [setDefaultShapeBorderColor, wbEngine]
  )

  if (!wbEngine) return null

  return (
    <>
      <ToolControlItem
        tooltip="Border Color"
        popperMenu={({ open, setOpen }) => (
          <ColorPicker
            onChangeColor={handleColorSelection}
            currentColor={wbEngine.selectedObjects[0]?.objects?.[0]?.stroke}
          />
        )}
      >
        <SvgIcon
          component={BorderColor}
          sx={{
            '#colorBar': {
              color: currentColor,
            },
          }}
        />
      </ToolControlItem>
    </>
  )
}

import { Accordion, Collapse, CollapseProps } from '@mui/material'
import { ScrollView } from 'packs/main/ScrollView/ScrollView'
import React, { ReactNode } from 'react'

const ScrollViewCollapse = (props: CollapseProps) => (
  <Collapse
    component={ScrollView as React.ComponentType<React.PropsWithChildren<unknown>>}
    {...props}
  />
)

export const InstructionStepAccordion = ({
  children,
  expanded,
  handleExpand,
}: {
  children: NonNullable<ReactNode>
  expanded: boolean
  handleExpand: () => void
}) => {
  return (
    <Accordion
      elevation={0}
      expanded={expanded}
      onChange={handleExpand}
      data-testid="instruction-step"
      TransitionComponent={ScrollViewCollapse}
      sx={(theme) => ({
        minHeight: theme.spacing(6),
        display: 'flex',
        flexDirection: 'column',
        '&:before': { height: '2px' },
        '&.Mui-expanded': { margin: 0 },
        '&.Mui-expanded:before': { opacity: 1 },
        '& .MuiCollapse-root': { overflow: 'scroll' },
      })}
    >
      {children}
    </Accordion>
  )
}

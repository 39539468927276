import CloseIcon from '@mui/icons-material/Close'
import {
  Box,
  Breakpoint,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React, { ReactElement } from 'react'

const createStyles = makeStyles({
  dialog: {
    "& [role='dialog']": {
      padding: '10px 10px 10px 0',
    },
  },
  title: {
    fontSize: '1.125rem',
    fontWeight: 'bold',
  },
  content: {
    fontSize: '0.875rem',
  },
  closeButton: {
    position: 'absolute',
    right: '2px',
    top: '2px',
  },
  confirmButton: {
    color: 'white',
    marginTop: '10px',
  },
})

interface CustomDialogProps {
  isOpen: boolean
  onClose: () => void
  onConfirm?: () => void
  title: ReactElement
  maxWidth?: Breakpoint | false
  confirmLabel?: string
  hideCancel?: boolean
}

export const CustomDialog: React.FC<React.PropsWithChildren<CustomDialogProps>> = ({
  isOpen,
  onClose,
  onConfirm,
  title,
  maxWidth,
  confirmLabel,
  hideCancel,
  children,
}) => {
  const styles = createStyles()

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth={maxWidth} className={styles.dialog}>
      <DialogTitle className={styles.title}>
        <Box sx={{ display: 'flex', columnGap: '8px' }}>{title}</Box>
        <IconButton onClick={onClose} className={styles.closeButton} size="large">
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent className={styles.content}>{children}</DialogContent>

      <DialogActions>
        {!hideCancel && (
          <Button size="large" variant="outlined" disableElevation onClick={onClose}>
            Cancel
          </Button>
        )}
        {confirmLabel != null && (
          <Button
            size="large"
            variant="contained"
            color="primary"
            disableElevation
            onClick={onConfirm}
          >
            {confirmLabel}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}

export default CustomDialog

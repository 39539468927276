import { Box, useTheme } from '@mui/material'
import { Question } from 'graphql/types'
import { Tab, TabList, TabPanel, Tabs } from 'packs/dashboard/components/Tabs'
import { getMonacoTheme } from 'packs/main/Monaco/MonacoThemes'
import React, { useEffect } from 'react'

import { useActiveQuestionVariant } from '../ExampleBody/ActiveQuestionVariantContext'
import { ProjectFiles } from './ProjectFiles'
import { ITabbedContentProps } from './Tabs'

export const StarterCodeForQuestionVariants: React.FC<
  React.PropsWithChildren<ITabbedContentProps>
> = ({ question }) => {
  const {
    palette: { mode },
  } = useTheme()
  const { questionVariants: variants } = question
  const { activeQuestionVariantId, setActiveQuestionVariantId } = useActiveQuestionVariant()

  useEffect(() => {
    if (activeQuestionVariantId == null && variants.length > 0) {
      setActiveQuestionVariantId(variants[0].id)
    }
  }, [activeQuestionVariantId, setActiveQuestionVariantId, variants])

  if (activeQuestionVariantId == null) {
    return null
  }

  return (
    <Box height="100%" display="flex">
      <Tabs
        size="small"
        value={`${activeQuestionVariantId}`}
        onSelectionChange={(selected) => setActiveQuestionVariantId(parseInt(selected))}
      >
        <TabList variant="scrollable">
          {variants.map((variant, idx) => (
            <Tab key={variant.id} value={`${variant.id}`} label={variant.display} />
          ))}
        </TabList>
        {variants.map((variant, idx) => (
          <TabPanel
            key={variant.id}
            value={`${variant.id}`}
            sx={{
              display: 'flex',
              flex: '1 1 auto',
              flexDirection: 'column',
              padding: 0,
              overflow: 'hidden',
            }}
          >
            <Box flexGrow={1} overflow="auto" p={1}>
              <ProjectFiles
                question={(variant as unknown) as Question}
                monacoTheme={getMonacoTheme(mode === 'dark')}
              />
            </Box>
          </TabPanel>
        ))}
      </Tabs>
    </Box>
  )
}

import { SvgIcon } from '@mui/material'
import React, { FC, useContext, useEffect, useState } from 'react'

import { FormatCenterAlign, FormatLeftAlign, FormatRightAlign } from '../../Toolbar/icons'
import { ToolbarsContext } from '../../Whiteboard'
import { ControlIconGrid } from '../ControlIconGrid'
import { ToolControlItem } from './ToolControlItem'

export const FontAlignmentControl: FC<React.PropsWithChildren<unknown>> = () => {
  const { wbEngine } = useContext(ToolbarsContext)
  const [textAlign, setTextAlign] = useState('center')

  useEffect(() => {
    const selectedObj = wbEngine?.selectedObjects[0]
    setTextAlign(selectedObj?.textAlign)
  }, [wbEngine?.selectedObjects, setTextAlign])

  if (!wbEngine) return null

  return (
    <>
      <ToolControlItem
        tooltip="Border Color"
        popperMenu={({ open, setOpen }) => (
          <ControlIconGrid
            numCols={3}
            icons={[
              {
                Icon: <SvgIcon component={FormatLeftAlign} />,
                onClick: () => {
                  wbEngine.setOnSelection({ textAlign: 'left' })
                  setTextAlign('left')
                },
                active: textAlign === 'left',
              },
              {
                Icon: <SvgIcon component={FormatCenterAlign} />,
                onClick: () => {
                  wbEngine.setOnSelection({ textAlign: 'center' })
                  setTextAlign('center')
                },
                active: textAlign === 'center',
              },
              {
                Icon: <SvgIcon component={FormatRightAlign} />,
                onClick: () => {
                  wbEngine.setOnSelection({ textAlign: 'right' })
                  setTextAlign('right')
                },
                active: textAlign === 'right',
              },
            ]}
          />
        )}
      >
        <SvgIcon
          component={
            textAlign === 'left'
              ? FormatLeftAlign
              : textAlign === 'right'
              ? FormatRightAlign
              : FormatCenterAlign
          }
        />
      </ToolControlItem>
    </>
  )
}

import React, { createContext, useCallback, useContext, useMemo, useState } from 'react'

interface IAppStateContext {
  appState: { [key: string]: any }
  set: (key: string, value: any) => void
}

const appStateContext = createContext<IAppStateContext>({
  appState: {},
  set: () => null,
})

interface IAppStateProviderProps {
  initialState?: { [key: string]: any }
}

/**
 * Provider for an arbitrary collection of properties known as "app state". This is not to be abused; it should be
 * used only for circumstances where the state of a deeply nested chunk of the UI needs to be lifted up to provide
 * access to other parts of the application (for instance using the "openness" of the side nav to add/remove padding
 * to the main content area).
 */
export const AppStateProvider: React.FC<React.PropsWithChildren<IAppStateProviderProps>> = ({
  children,
  initialState,
}) => {
  const [appState, setAppState] = useState<{ [key: string]: any }>(initialState || {})

  const set = useCallback(
    (key: string, value: any) => {
      setAppState({ ...appState, [key]: value })
    },
    [appState]
  )

  const contextValue = useMemo(() => {
    return { appState, set }
  }, [appState, set])

  return <appStateContext.Provider value={contextValue}>{children}</appStateContext.Provider>
}

/**
 * Hook to gain access to the AppState and it's setter function. Components will typically use the
 * `useAppStateProperty` hook defined below. This hook can be used if access to several properties at a time is
 * necessary.
 */
export function useAppState() {
  const context = useContext(appStateContext)

  if (!context) {
    throw new Error('`useAppState` hook must be a descendant of an `AppStateProvider`')
  }

  return context
}

/**
 * Hook to gain access to the value for a given app state property. Given a property name, this hook will return
 * both the value of the property and a setter to set that property's value in the AppState context.
 */
export function useAppStateProperty(propertyName: string) {
  const { appState, set } = useAppState()

  const propertyValue = useMemo(() => {
    return appState[propertyName]
  }, [propertyName, appState])

  const setPropertyValue = useCallback(
    (value: any) => {
      set(propertyName, value)
    },
    [set, propertyName]
  )

  return { value: propertyValue, set: setPropertyValue }
}

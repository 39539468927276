import React, { FC, useContext } from 'react'

import { SendToBack } from '../../Toolbar/icons'
import { ToolbarsContext } from '../../Whiteboard'
import { ToolControlItem } from './ToolControlItem'

export const MoveSelectionBackwardControl: FC<React.PropsWithChildren<unknown>> = () => {
  const { wbEngine } = useContext(ToolbarsContext)
  if (!wbEngine) return null

  return (
    <>
      <ToolControlItem
        tooltip="Send Backward"
        onClick={() => {
          wbEngine.sendSelectionToBack()
        }}
      >
        <SendToBack />
      </ToolControlItem>
    </>
  )
}
